import React ,{ useEffect, useRef, useState } from 'react';
import { fabric } from "fabric-with-gestures";
import axios from 'axios';
import axiosInstance from '../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import jQuery from "jquery";
import { SVG, extend as SVGextend, Element as SVGElement, G } from '@svgdotjs/svg.js'
import { connect } from 'react-redux'
import './View.css';
import { useNavigate } from 'react-router-dom'
import LeftProfile from  './component/LeftProfile';
import ElementBox from './component/ElementBox';
import RightMenu from './component/RightMenu';
import AppLeftPanel from './component/AppLeftPanel';
import AppUnassignedPanel from './component/AppUnassignedPanel';
import SetBoothModal from './component/SetBoothModal'
import SetAisleModal from './component/SetAisleModal'
import SetNewPasswordModal from './component/SetNewPasswordModal'
import SignupModal from './component/SignupModal'
import ResetPasswordModal from './component/ResetPasswordModal'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import MoonLoader from 'react-spinners/MoonLoader'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ListIcon from '@mui/icons-material/List';
import LZString from 'lz-string';
import  area  from 'area-polygon'
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, redrawobjectborder, adjustobjectborder, setObjectBG, changeBorderColor, changeSingleBG, getBGColor, addText, composeLoadObjects, initCanvas, afterCanvasLoad, changeBorderWidth, px2unit, unit2Px,addLocationIcon,removeLocationIcon,locationScaling,LightenDarkenColor,getBoothByIcon, toggleDrawMode, checkIfSelectable, scaleTextsCenterFocus, swichFullOverView, sendBackIntersects, checkifrectbooth, getStrokeWidth} from '../utils/CanvasTool'
import CircularProgress from '@mui/material/CircularProgress';

import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ToolBox from './ToolBox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import BoothDrawer from './component/AppLeftPages/BoothDrawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Hidden from '@mui/material/Hidden';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Notfound from './component/Notfound'
import { ReactComponent as LegendIcon } from '../svgs/legend1.svg';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import ExportFile from './component/ExportFile';
import { useParams } from 'react-router-dom'
// import Carousel  from "react-multi-carousel";
import { makeStyles } from '@mui/styles';
import  Carousel from 'react-material-ui-carousel'
import SponsorCarousel from "./component/SponsorCarousel"
import ShowMore from 'react-show-more';
import FeaturedLabel from './component/FeaturedLabel';
import ReactPlayer from 'react-player'
import {
    BrowserRouter as Router,
    Link,
    useLocation
  } from "react-router-dom";

import { API_URL, ESHOW_URL, LOCATION_WIDTH } from '../config.js'
import { setBoothLoaded, setMainCanvas,setBgImgCanvas, setBgCanvas, setMainSvg, setImportLayers, setMainLayers, setBoothList, setBoothFileList, setCanvasRefresh, setLoading, setActiveBoothFile, setMenuControl, setShowGrid, resetMain, setPricingOptions, setPremiumOptions, setDiscountOptions, setGroupOptions, setExhibitorList, setCategoryOptions, setAuthInfo, setFirstMapContent, setFirstBgContent, setShowSetup,setSponsorshipsOptions, setCartList, setActiveMapKey } from '../actions/main'
import { setSnackMsg, setOpenSnack, setCustomStyles } from '../actions/ui'
import { setBoothHistory } from '../actions/booth_history'
import { setOpenCompanyProfile, setOpenUnassignedProfile, resetUI, setOpenBoothList, setLeftPage, setOpenLogin, setOpenSponsorshipProfile } from '../actions/ui'
import Snackbar from '@mui/material/Snackbar';
import { parseToken, buildObjectProperty, download, containsObject, convertRgbHex, convertRgb, getDefaultGroup, uuidRegexExp, isUUID, setCookie, getCookie, getTokenCookie, getTokenPlanner, assetUrl, getCartCookie, detectEnvironment} from '../utils/Common'
var leftRuler, topRuler
var g_zoomTimeout =false
var g_carts;
var g_ajaxFailCnt = 0
var watermark = 0;
var intervalLoadMapPeriodically
var bgImgCanvas
var bgCanvas
var g_groupOptions
var g_mouseUpFlag = false
var g_authInfo = false
var pinchFlag = false
var zoomStartScale = 1;
var g_mouseOutTimeout = false
var preScale = 1;
var pausePanning;
var g_mouseoverObject;
var axiosController
var g_mouseEvent;
var isZooming = false;
var isMoving = false;
var g_opt = false

var lastScale = 1;
var minScale = 0.5;
var maxScale = 3;
var isPinching = false;
var initialDistance = 0;
var initialCenter = { x: 0, y: 0 };
let initialTouchData = [];
let canvasBoundaryWidth = 0;
let canvasBoundaryHeight = 0;
function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function MainViewer(props) {
    const navigate = useNavigate()
    const { id1, id2, id3, id4 } = useParams();
    let query = useQuery();
    const mainCanvasRef = useRef(null);
    const mainCanvasDiv = useRef(null);
    const mouseModalRef = useRef(null);
    const mouseCursorRef = useRef(null);
    const loadingImagRef = useRef(null);
    const [openSetBoothModal, setOpenSetBoothModal] = useState(false);
    const [openSetAisleModal, setOpenSetAisleModal] = useState(false);
    const [selectedBooth,setSelectedBooth] = useState(null);
    const [selectedAisle, setSelectedAisle] = useState(null);
    const [rightDragPopup, setRightDragPopup] = useState(false)
    const [boothGroups , setBoothGroups] = React.useState([]);
    const [snackMsg, setSnackMsg] = useState('');
    const [openSnack, setOpenSnack] = useState(false);
    const [ajaxFailCnt, setAjaxFailCnt] = useState(0);
    const [sponsorList, setSponsorList] = useState([]);   
    const [active_booth_file_select, setActiveBoothFileSelect] = useState(false);
    const [maincanvas_width, setMaincanvasWidth] = useState(false)
    const [maincanvas_height, setMaincanvasHeight] = useState(false)
    const [openExportModal, setOpenExportModal] = useState(false);
    const [exportSvg, setExportSvg] = useState(null);
    const [mouseoverObject, setMouseOverObject] = useState(false)
    const [mouseOverExibitorInfo, setMouseOverExibitorInfo] = useState(false)
    const [mouseOverExibitorKeyList, setMouseOverExibitorKeyList] = useState([])
    const [mouseOverBoothInfo, setMouseOverBoothInfo] = useState(false)    
    const [selectedSponsorshipsObject, setSelectedSponsorshipsObject] = useState(null);
    if(props.cartPage){
        canvasBoundaryWidth = props.width;
        canvasBoundaryHeight = props.height;
    }
    else{
        canvasBoundaryWidth = window.innerWidth;
        canvasBoundaryHeight = window.innerHeight;
    }
    
    var mainOffsetX = 50;
    var mainOffsetY = 50;
    var imageOffsetX, imageOffsetY;
    var view_file = props.main.view_file;
    var mainCanvas = props.main.mainCanvas;
    var mainLayers = props.main.mainLayers;
    var mainSvg = props.main.mainSvg
    var boothFileList = props.main.boothFileList;
    var boothFileListUpdated = props.main.boothFileListUpdated
    var pricingOptions = props.main.pricingOptions
    var premiumOptions = props.main.premiumOptions
    var discountOptions = props.main.discountOptions
    var authInfo = props.main.authInfo
    var active_booth_file = props.main.active_booth_file
    
    const useStyles = makeStyles((theme) => ({
        btnLabel: {
            background:'white',
            "& .MuiSelect-select":{
                paddingTop:0,
                paddingBottom:0
            },
            color: props.ui?.customStyles?.['secondary-menu-icon-color']?props.ui?.customStyles['secondary-menu-icon-color']:'white',
            "&:hover": {
                color: props.ui?.customStyles?.['secondary-menu-icon-color-hover']?props.ui?.customStyles['secondary-menu-icon-color-hover']:'#eeeeee',
            },
            "&.active": {
                color: props.ui?.customStyles?.['secondary-menu-icon-color-hover']?props.ui?.customStyles['secondary-menu-icon-color-hover']:'#eeeeee',
            },
        },
        footer:{
            background:props.ui?.customStyles?.['footer-bg-color']?(props.ui?.customStyles['footer-bg-color']+"!important"):'white',
        }
    }));
    const classes = useStyles();

      
    const fetchSponsorList = ()=>{
        var url = `${ESHOW_URL()}?method=getSponsors`;
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axiosInstance.get(url, options).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                if(result.SPONSORS){
                  var buffer = [];
                  for(var i = 0; i< result.SPONSORS.length; i++){
                    var item = result.SPONSORS[i]
                    buffer.push(
                      {
                        description:item['DESCRIPTION']?item['DESCRIPTION']:"",
                        url:item['LINK']?item['LINK']:"",
                        KEY_ID:item['KEY_ID']?item['KEY_ID']:"",
                        name:item['TITLE']?item['TITLE']:"",
                        logo:item['LOGO']&&item['LOGO']!=""?item['LOGO']:null,
                      }
                    )
                  }              
                  setSponsorList(buffer)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }
    const handleCloseSetBoothModal = ()=>{
        setOpenSetBoothModal(false)
    }
    const handleCloseSetAisleModal = ()=>{
        setOpenSetAisleModal(false)
    }
    const handleCloseSnack = ()=>{
        setOpenSnack(false);
    }
    const findGroupById = (id)=>{
        var group_objects = mainCanvas.getObjects('group');
        for(var j = 0; j < group_objects.length; j++){
            var group_object = group_objects[j]
            if(group_object.id == "booth-"+id){
                return group_object;
            }
        }
        return null;
    }
    const handleConfirmSetBoothModal = ()=>{
        setOpenSetBoothModal(false)
        var boothList = [...props.main.boothList];
        for(var i = 0; i < boothList.length; i++){
            var booth = boothList[i]
            if(booth.id == selectedBooth.id){
                boothList[i] = selectedBooth
                props.setBoothList(boothList)                
                props.setBoothHistory({boothList:boothList, content: (props.main.mainCanvas.toDatalessObject())})
                var group_object = findGroupById(booth.id);
                var textObjs= group_object.getObjects('text');
                if(textObjs && textObjs.length>0){
                    textObjs[0].set('text', selectedBooth.boothName)
                    mainCanvas.requestRenderAll();
                }
                break;
            }
        }
    }
    const setCanvas = (canvas)=>{
        var activeFile = active_booth_file;
        
        if(activeFile){
            canvas.set({
                convertRateX:activeFile['convertRateX'], 
                convertRateY:activeFile['convertRateY'], 
                convertOffsetX:activeFile['convertOffsetX'], 
                convertOffsetY:activeFile['convertOffsetY'], 
                startX:activeFile['startX'], 
                startY:activeFile['startY'], 
                endX:activeFile['endX'], 
                endY:activeFile['endY'],
                mapWidth:activeFile['mapWidth'],
                mapHeight:activeFile['mapHeight'],
                mapUnit:activeFile['mapUnit']?activeFile['mapUnit']:"feet", 
                convertUnit:activeFile['convertUnit'],
                gridSize:activeFile['gridSize'],
                showGrid:activeFile['showGrid'],
                strokeWidth:activeFile['strokeWidth'],
                gridAngle:activeFile['gridAngle'],
                gridOffsetX:activeFile['gridOffsetX'], 
                gridOffsetY:activeFile['gridOffsetY'], 
                mapOffsetX:activeFile['mapOffsetX'], 
                mapOffsetY:activeFile['mapOffsetY'],  
                header_banner:activeFile['header_banner'], 
                header_color:activeFile['header_color'], 
                boothfile_brand:activeFile['boothfile_brand'], 
                LIST_PENDING_EXHIBITORS: activeFile.LIST_PENDING_EXHIBITORS,
                LIST_TBD_EXHBITORS: activeFile.LIST_TBD_EXHBITORS ,
            })
            canvas.renderAll()
        }
    }
    
   
    const fetchSetup =async (map_key)=>{
        var url = `${ESHOW_URL()}?method=getSetup&map_key=${map_key}`
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        const response = await axiosInstance.get(url, options)
        var result = false;
        if(response.status == 200){
            var data = response.data
            result = data
            if(data.SUCCESS == true){
                result = data
            }
            else{
                props.setOpenSnack(true);
                props.setSnackMsg((data.MESSAGE?(" "+data.MESSAGE):""));
            }
        }
        else{
            console.log("")
        }
        return result;
    }

    

    const fetchGroupList =async ()=>{
        var url = `${ESHOW_URL()}?method=getBoothGroups`;
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axiosInstance.get(url, options).then(res=>{
            var result = res.data;
            if(result.length > 0){
                for(var i = 0; i < result.length; i++){
                    result[i].TITLE = result[i].TITLE.trim()
                }
            }
            
            result.sort(function (a, b) {
                return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
            });
            props.setGroupOptions(result)
            g_groupOptions = result
        }).catch(error => {
        })  
    }

    const fetchCartList =async ()=>{
        var active_booth_file = props.main.active_booth_file
        
        var  map_key = active_booth_file.KEY_ID;
        var  form_key;
        if(props.main.authInfo){
            form_key = active_booth_file.form_key
        }
        if(!form_key) return
        var query = `${ESHOW_URL()}?method=getCart&form_key=${form_key}` 
        var parent_key = props.main.authInfo.SALES?.PARENT_KEY
        if(parent_key && parent_key!= ''){
            query = `${query}&parent_key=${parent_key}`
        }    
        else{
            parent_key = getCartCookie();
            query = `${query}&parent_key=${parent_key}`
        }
        var url = query;
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axiosInstance.get(url, options).then(res=>{
            var result = res.data;
            var data =result.CART 
            props.setCartList(data)
        }).catch(error => {
        })  
    }
    
    const fetchSponsorshipsList =async ()=>{
        var url = `${ESHOW_URL()}?method=getSponsorshipList`
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axiosInstance.get(url, options).then(res=>{
            var result = res.data;
            if(result.SUCCESS){  
                if(result.SPONSORSHIPS){     
                    var data =result.SPONSORSHIPS 
                    props.setSponsorshipsOptions(data)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }

    const fetchExhibitorList =async ()=>{
        var url = `${ESHOW_URL()}?method=getExhibitorList`;
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axiosInstance.get(url, options).then(res=>{
            var result = res.data;            
            props.setExhibitorList(result)
        }).catch(error => {
        })  
    }


    const fetchPricingOptions =async ()=>{
        var url = `${ESHOW_URL()}?method=getSales&product_type=sales`
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axiosInstance.get(url, options).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                if(result.SALES){     
                    var data =result.SALES 
                    data.sort(function (a, b) {
                        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                    });
                    props.setPricingOptions(data)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }

    const fetchPremiumOptions =async ()=>{
        var url = `${ESHOW_URL()}?method=getSales&product_type=Premiums`
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axiosInstance.get(url,options).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                if(result.PREMIUMS){     
                    let data = result.PREMIUMS
                    data.sort(function (a, b) {
                        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                    });
                    props.setPremiumOptions(data)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }
    
    const fetchDiscountOptions =async ()=>{
        var url = `${ESHOW_URL()}?method=getSales&product_type=discounts`
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axiosInstance.get(url, options).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                if(result.DISCOUNTS){     
                    let data = result.DISCOUNTS
                    data.sort(function (a, b) {
                        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                    });
                    props.setDiscountOptions(data)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }

    const fetchMechnantAccount =async ()=>{
        {
          axiosInstance.get(`/getmechantaccount?boothId=''&show_key=${authInfo.show_key}`).then(res=>{
            // console.log("getmechantaccount", res.data)     
            if(res.data){
              props.setMechantAccount(res.data)
            }     
          }).catch(error => {
              //console.log("error", error);
          })
        }
    }

    const fetchCategoryOptions =async (map_key)=>{
        // {
        //   axiosInstance.get(`/getcategorylist?boothId=''&show_key=${authInfo.show_key}`).then(res=>{
        //     //console.log(res.data)     
        //     if(res.data){
        //       props.setCategoryOptions(res.data)
        //     }     
        //   }).catch(error => {
        //       //console.log("error", error);
        //   })
        // }
        if(!map_key) return
        var url = `${ESHOW_URL()}?method=getProductCategories&map_key=${map_key}`
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axiosInstance.get(url, options).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                if(result.CATEGORIES){        
                    let data = result.CATEGORIES
                    data.sort(function (a, b) {
                        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                    });          
                  props.setCategoryOptions(data)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }  

    const convertGroupIdArray=(keys)=>{
        var output = [];
        var groupOptions = g_groupOptions;
        for(var i = 0; i < keys?.length; i++){
            var result = groupOptions.find(item => item.KEY_ID?.toLowerCase() === keys[i]?.toLowerCase());
            if(result){
                output.push(result.TITLE?.trim())
            }
        }
        return output
    }

    const StartSetBoothModal = (id) =>{
        var boothList = props.main.boothList;
        for(var i = 0; i < boothList.length; i++){
            var booth = boothList[i]
            if("booth-"+booth.id == id){
                setSelectedBooth(booth)
                setOpenSetBoothModal(true)
                break;
            }
        }
    }

    const handleConfirmSetAisleModal = (name)=>{
        setOpenSetAisleModal(false)
        var textObjs= selectedAisle.getObjects('text');
        if(textObjs && textObjs.length>0){
            textObjs[0].set('text', name)
            mainCanvas.requestRenderAll();
        }
    }

    const StartSetAisleModal = (aisle) =>{     
        setSelectedAisle(aisle)   
        setOpenSetAisleModal(true)
    }

    const onChangeSelectedBooth = (key, value)=>{
        var booth = {...selectedBooth}
        booth[key] = value
        setSelectedBooth(booth)
    }

    useEffect(() => {
        if(authInfo.authenticated == 3){
            var paddinTop = 64;
            var paddingBottom = 65;
            var paddingLeft = 65;
            var sales_header_show = true
            if(props.main.authInfo && props.main.authInfo.tokenInfo){
                if(props.main.authInfo.tokenInfo.HIDE_TOP_NAVIGATION){
                    sales_header_show = false
                }
            }
            if(props.main.authInfo && props.main.authInfo.SALES){
                if(props.main.authInfo.SALES.RIGHT_MENU == 'hide'){
                    // paddinTop-= 55 //remove header
                }
                if(props.main.authInfo.SALES.SPONSORS == 'hide'){
                    paddingBottom = 0
                }
                if(props.main.authInfo.SALES.MAP_TYPE == 'sales' || !sales_header_show || props.cartPage){
                    paddinTop-= 64 //remove header
                }   
                if(props.main.authInfo.SALES.LEFT_DRAWER == 'hide'){
                    paddingLeft= 0 //remove header
                } 
                if(canvasBoundaryWidth<= 900) {
                    paddingLeft= 0 //remove header
                }
            }
            if(props.width){
                setMaincanvasWidth(props.width)
                setMaincanvasHeight(props.height)
            }
            else{
                setMaincanvasWidth(canvasBoundaryWidth-paddingLeft)
                setMaincanvasHeight(canvasBoundaryHeight-paddinTop-paddingBottom)
            }
            if(!props.cartPage)
                document.getElementsByTagName("body")[0].style.overflowY='hidden'
            g_authInfo = authInfo
        }
        return () => {
            document.getElementsByTagName("body")[0].style.overflowY='auto'
        };
    }, [authInfo.authenticated])

    useEffect(()=>{
        setActiveBoothFileSelect(active_booth_file?.KEY_ID)
        fetchCartList()
    },[active_booth_file])

    useEffect(()=>{
        g_mouseoverObject = mouseoverObject
    },[mouseoverObject])

    useEffect(() => {
        if(authInfo.authenticated == 3 && maincanvas_width){
            window.addEventListener("contextmenu", e => e.preventDefault());
            // topRuler = new fabric.Canvas('top-ruler');
            // leftRuler = new fabric.Canvas('left-ruler');
            if(mainCanvasRef.current){
                fabric.Object.prototype.toDatalessObject = (function (toDatalessObject) {
                    return function () {
                        return fabric.util.object.extend(toDatalessObject.call(this), buildObjectProperty(this));
                    };
                })(fabric.Object.prototype.toDatalessObject);


                fabric.Polygon.prototype.toDatalessObject = (function (toDatalessObject) {
                    return function () {
                    
                        return fabric.util.object.extend(toDatalessObject.call(this), {
                            id: this.id,
                            pathOffset: this.pathOffset,
                            scaleX: this.scaleX,
                            scaleY: this.scaleY,
                        });
                    };
                })(fabric.Polygon.prototype.toDatalessObject);

                fabric.Object.prototype.objectCaching = false//true
                fabric.Object.prototype.noScaleCache = false
                
                const originalRender = fabric.Group.prototype.render;

                fabric.Group.prototype.render = function(ctx, noTransform) {
                    if (this.isOnScreen()) {
                        return originalRender.call(this, ctx, noTransform);
                    }
                    return originalRender.call(this, ctx, noTransform);
                    return;
                };


                let canvas = new fabric.Canvas("canvas");

                canvas.defaultCursor = 'default';
                canvas.hoverCursor= 'pointer';
                canvas.setBackgroundColor('transparent', canvas.renderAll.bind(canvas)
                );
                canvas.set({preserveObjectStacking: true})
                
                var bgImgCanvasEle = cloneCanvas(canvas);
                bgImgCanvasEle.setAttribute('id', 'bg-img-canvas');
                bgImgCanvas = new fabric.StaticCanvas("bg-img-canvas");
                props.setBgImgCanvas(bgImgCanvas) 

                var bgCanvasEle = cloneCanvas(canvas);
                bgCanvasEle.setAttribute('id', 'bg-canvas');
                bgCanvas = new fabric.StaticCanvas("bg-canvas");
                props.setBgCanvas(bgCanvas)
                
                var boothList = props.booth_history.boothList
                var content = props.booth_history.content
                renderBorder(canvas, true);   
                if(boothList && content){ 
                    props.setLoading(true)
                    // mainCanvasDiv.current.className = 'hidden';
                    canvas.loadFromJSON(content, ()=>{
                        var groups = canvas.getObjects('group');
                        for(var i = 0; i< groups.length; i++){
                            groups[i].set({
                                borderColor: '#ff7777',
                                cornerColor: 'red',
                                cornerSize: 6,
                                transparentCorners: false
                            });
                            groups[i].set({
                                borderColor: '#ff7777',
                                cornerColor: 'red',
                                cornerSize: 6,
                                transparentCorners: false,
                                lockScalingX: true,
                                lockScalingY: true,
                            });
                            var layer = groups[i].get('layer'); 
                            if(layer == "original"){
                                groups[i].set({
                                    visible:false,
                                    lockMovementX: true,
                                    lockMovementY: true,
                                    lockRotation: true,
                                    lockScalingX: true,
                                    lockScalingY: true,
                                    selection:false,
                                    hasControls:false,
                                    hasBorders:false,
                                    evented:false,
                                    selectable: false ,
                                })     
                            }
                        }

                        // setCanvas(canvas);
                        
                        initCanvas(canvas, active_booth_file, bgCanvas, bgImgCanvas)
                        centerFocus(canvas)
                                           
                        // redrawRulers(canvas);
                        canvas.setBackgroundColor('transparent', mainCanvas.renderAll.bind(mainCanvas));
                        bgImgCanvas.setBackgroundColor('transparent', bgImgCanvas.renderAll.bind(bgImgCanvas));
                        bgCanvas.setBackgroundColor('transparent', bgCanvas.renderAll.bind(bgCanvas));
                        
                        props.setMainCanvas(canvas);
                        props.setLoading(false)
                        // mainCanvasDiv.current.className = 'visible';
                        // canvas.renderAll()
                        var bgContent = props.main.firstBgContent
                        canvas.renderAll();
                        if(bgContent){
                            bgImgCanvas.loadFromJSON(bgContent, ()=>{                                
                                centerFocus(canvas)                                
                                toggleDrawMode(canvas, "general")
                            })
                        }
                        else{
                        }
                        props.setCanvasRefresh(true)
                    })
                    
                    
                    renderBorder(canvas, true);  //redraw to kill event  
                    canvas.requestRenderAll();
                    // removeGrid(canvas)
                }
                else{
                    props.setMainCanvas(canvas);
                }

               

                var boothList = props.booth_history.boothList
                var content = props.booth_history.content
                
                
                fabric.util.removeListener(document.getElementsByClassName('upper-canvass main-canvas')[0], 'contextmenu' , onContextMenu);
                fabric.util.addListener(document.getElementsByClassName('upper-canvas main-canvas')[0], 'contextmenu', (e)=>onContextMenu(e, canvas));


                canvas.off('object:moving')
                
                redrawRulers(canvas);
                props.setMainCanvas(canvas);
                
            }
        }
    }, [authInfo.authenticated, maincanvas_width])

    const cloneCanvas = (canvas)=> {
        const clone = document.createElement('canvas');
        clone.width = canvas.width;
        clone.height = canvas.height;
        canvas.wrapperEl.appendChild(clone);
        return clone;
    }

    useEffect(() => {
        if(view_file){
            var formdata = 
            {
                "action":"svg_creation",
                "converter":"AutoXchange AX2020",
                "version":"V1.00",
                "contentType":"file",
                "contentLocation":`${API_URL()}/content/drawings/dwg/${view_file}`,
                "contentFormat":"DWG",
                "contentUsername":"",
                "contentPassword":"",
                "userLabel":"fromCADViewerJS",
                "contentResponse":"stream",
                "leaveStreamOnServer":0,
                "parameters":[
                    {"paramName":"f",
                    "paramValue":"svg"},
                    // {"paramName":"RL",
                    // "paramValue":"RM_"},
                    // {"paramName":"TL","paramValue":"RM_TXT"},
                    // {"paramName":"LA","paramValue":""},
                    {"paramName":"model","paramValue":""},
                    {"paramName":"fpath","paramValue":"/converters/ax2020/windows/fonts/"}
                ]
            }
            var data = qs.stringify({
                request: JSON.stringify(formdata)
              });
            // var bodyFormData = new FormData();
            // bodyFormData.append('request', JSON.stringify(formdata));
            const options = {
                headers: {"content-type": 'application/x-www-form-urlencoded'}
            }
            axiosInstance.post('/callapiconversion', data, options).then(res=>{
                const result = res.data;
                initBoothFileList();
                getConvertedSvg(result);
            }).catch(error => {
                console.log("error", error);
            })
            if(props.main.mainCanvas){
                
                var group_objects = mainCanvas.getObjects('group');
                for(var j = 0; j < group_objects.length; j++){
                    var group_object = group_objects[j]
                    props.main.mainCanvas.remove(group_object);
                }    
                mainCanvas.clear();      
                // bgImgCanvas.clear()  
                mainCanvas.viewportTransform[0] = 1;
                mainCanvas.viewportTransform[3] = 1;
                mainCanvas.viewportTransform[4] = 0;
                mainCanvas.viewportTransform[5] = 0;
                props.main.mainCanvas.setBackgroundColor('transparent', mainCanvas.renderAll.bind(mainCanvas)
                );
                bgImgCanvas.setBackgroundColor('transparent', bgImgCanvas.renderAll.bind(bgImgCanvas));
                bgCanvas.setBackgroundColor('transparent', bgCanvas.renderAll.bind(bgCanvas));
                props.main.mainCanvas.requestRenderAll()

                
                bgImgCanvas.viewportTransform[0] = 1;
                bgImgCanvas.viewportTransform[3] = 1;
                bgImgCanvas.viewportTransform[4] = 0;
                bgImgCanvas.viewportTransform[5] = 0;
                
                bgCanvas.viewportTransform[0] = 1;
                bgCanvas.viewportTransform[3] = 1;
                bgCanvas.viewportTransform[4] = 0;
                bgCanvas.viewportTransform[5] = 0;
            }
        }
        else{
        }          
    }, [view_file])

    const loadBoothById=async (id= null, boothFileList)=>{        
        var activeFile;
        for(var i = 0; i <boothFileList.length; i++){
            if(boothFileList[i]['KEY_ID'] == id){
                activeFile = boothFileList[i];
                break;
            }
        }
        if(id && activeFile && activeFile.KEY_ID){    
            mainCanvas.clear();    bgImgCanvas.clear() ; bgCanvas.clear() 
            mainCanvas.viewportTransform[0] = 1;
            mainCanvas.viewportTransform[3] = 1;
            mainCanvas.viewportTransform[4] = 0;
            mainCanvas.viewportTransform[5] = 0;
            
            bgImgCanvas.viewportTransform[0] = 1;
            bgImgCanvas.viewportTransform[3] = 1;
            bgImgCanvas.viewportTransform[4] = 0;
            bgImgCanvas.viewportTransform[5] = 0;
            
            bgCanvas.viewportTransform[0] = 1;
            bgCanvas.viewportTransform[3] = 1;
            bgCanvas.viewportTransform[4] = 0;
            bgCanvas.viewportTransform[5] = 0;

            var mapJS = activeFile.MAPJS;
            mapJS = mapJS.replaceAll('\r\n', ' ')
            mapJS = JSON.parse(mapJS)
            if(!mapJS) mapJS = {}
            var settingData = await fetchSetup(activeFile.KEY_ID);
            var convertRateX= Math.abs(mapJS.convertRateX)
            var mapWidthPx = unit2Px(settingData?.CANVAS?.SIZE_X?settingData?.CANVAS?.SIZE_X:canvasBoundaryWidth, convertRateX, settingData?.GENERAL?.UNIT?settingData?.GENERAL?.UNIT:'feet')
            var mapHeightPx = unit2Px(settingData?.CANVAS?.SIZE_Y?settingData?.CANVAS?.SIZE_Y:canvasBoundaryHeight, convertRateX, settingData?.GENERAL?.UNIT?settingData?.GENERAL?.UNIT:'feet')
            var tmp = {
                mapWidth:settingData?.CANVAS?.SIZE_X, 
                mapHeight:settingData?.CANVAS?.SIZE_Y,
                mapUnit:settingData?.GENERAL?.UNIT?settingData?.GENERAL?.UNIT:'feet', 
                convertUnit:settingData?.GENERAL?.CONVERT_UNIT?settingData?.GENERAL?.CONVERT_UNIT:'feet', 
                gridSize:settingData?.CANVAS?.GRID_SIZE?settingData?.CANVAS?.GRID_SIZE:10, 
                showGrid:settingData?.CANVAS?.GRID_ON, 
                strokeWidth:settingData?.CANVAS?.STROKE_WIDTH, 
                snapToGrid: settingData?.CANVAS?.GRID_SNAP, 
                mapOffsetX: settingData?.MAP_OFFSET?.OFFSET_X, 
                mapOffsetY: settingData?.MAP_OFFSET?.OFFSET_Y, 
                gridAngle: settingData?.CANVAS?.GRID_ANGLE,
                boothfile_brand: settingData?.GENERAL?.BRAND_LOGO,
                header_banner: settingData?.GENERAL?.HEADER_BANNER,
                header_color: settingData?.GENERAL?.HEADER_COLOR,
                // sales_form: sales_form,
                event_home: settingData?.GENERAL?.HOME_LINK,
                form_key: settingData?.FORMS?.[0]?.KEY_ID,
                form_title: settingData?.FORMS?.[0]?.TITLE,
                endX:mapWidthPx,
                endY:mapHeightPx,
                startX:0,
                startY:0,
                convertOffsetX: settingData?.DWG?.convertOffsetX,
                convertOffsetY: settingData?.DWG?.convertOffsetY,
                svgToWorldUnits: settingData?.DWG?.svgToWorldUnits,
                LIST_PENDING_EXHIBITORS: settingData?.SETUP?.LIST_PENDING_EXHIBITORS,
                LIST_TBD_EXHBITORS: settingData?.SETUP?.LIST_TBD_EXHBITORS ,
                loaded:true
            }

            var content
            mapJS = {...mapJS, ...tmp}
            if(mapJS.mainContent && mapJS.mainContent != ""){
                content = JSON.parse(mapJS.mainContent)
            }                
            initCanvas(mainCanvas, mapJS, bgCanvas, bgImgCanvas)
            centerFocus(null, false)
            renderBorder(mainCanvas); 
            props.setLoading(true)
            props.setActiveBoothFile({...activeFile, ...mapJS})
            
            var url = `${ESHOW_URL()}?method=getMapData&map_key=${id}&token=${getTokenCookie()}`
            // var url = `https://dev.goeshow.com/webservices/eshow/floor_space.cfc?method=getMapData&map_key=${id}&token=${getTokenCookie()}`
            // axiosInstance.get(`/getMapData?id=${id}&show_key=${authInfo.show_key}&user_key=${authInfo.user_key}&token=${getTokenCookie()}`).then(async res=>{
            axiosInstance.get(url).then(async res=>{
                setCookie("last_map", id, 7);
                if(!res.data){
                    props.setLoading(false)
                    props.setAuthInfo({show_key:authInfo.show_key, user_key:authInfo.user_key,user_name:authInfo.user_name,SALES:authInfo.SALES, BOOTH_PRICING:authInfo.BOOTH_PRICING, SHOPPING_CART:authInfo.SHOPPING_CART, authenticated: 2, tokenInfo:authInfo.tokenInfo})
                    return
                }
                try{                    
                    props.setBoothLoaded(false)
                    watermark = res.data.WATERMARK
                    var mapRemoveObjects = [];    
                    var objects = composeLoadObjects(res.data, mapRemoveObjects, mapJS, bgImgCanvas, props.setFirstBgContent)
                    
                    content.objects = objects
                    props.setActiveBoothFile({...activeFile, ...mapJS})

                } catch (e) {
                    // props.setActiveBoothFile(activeFile)
                    // props.setOrgJson(JSON.stringify(content))
                    mainCanvas.clear(); bgImgCanvas.clear(); bgCanvas.clear(); 
                    mainCanvas.viewportTransform[0] = 1;
                    mainCanvas.viewportTransform[3] = 1;
                    mainCanvas.viewportTransform[4] = 0;
                    mainCanvas.viewportTransform[5] = 0;
                    console.log("Map JSON Error", e)
                    
                    bgImgCanvas.viewportTransform[0] = 1;
                    bgImgCanvas.viewportTransform[3] = 1;
                    bgImgCanvas.viewportTransform[4] = 0;
                    bgImgCanvas.viewportTransform[5] = 0;
                    
                    bgCanvas.viewportTransform[0] = 1;
                    bgCanvas.viewportTransform[3] = 1;
                    bgCanvas.viewportTransform[4] = 0;
                    bgCanvas.viewportTransform[5] = 0;


                    props.setLoading(false)
                    return;
				}

                if(content){
                    if(content && content.backgroundImage && content.backgroundImage.src){
                        content.backgroundImage.src = assetUrl(content.backgroundImage.src)
                    }
                    var startTime = Math.floor(Date.now() / 100)
                    var canvas = mainCanvas;
                    fabric.Object.prototype.objectCaching = false
                    canvas.renderOnAddRemove = false
                    if(canvas){
                        canvas.loadFromJSON(content, ()=>{
                            initCanvas(mainCanvas, mapJS, bgCanvas, bgImgCanvas)
                            centerFocus(null, false)
                            // canvas.renderAll()
                            
                            var groups = canvas.getObjects();
                            for(var i = 0; i< groups.length; i++){
                                var layer = groups[i].get('layer'); 
                                groups[i].set({
                                    borderColor: 'green',
                                    cornerColor: 'red',
                                    cornerSize: 6,
                                    hasControls: false,
                                    cornerSize: 6,
                                    transparentCorners: false,
                                });
                                if(groups[i].class == 'table'){
                                    var sub_objects = groups[i].getObjects('group');
                                    if(sub_objects && sub_objects.length > 0){
                                        // if(sub_objects[0].class == "path-group"){
                                            sub_objects[0].set({
                                                left:NaN,
                                                top: NaN,
                                            })
                                            .setCoords();
                                        // }
                                    }
                                }
                            }
                            

                            var objects = content.objects;
                            var showGrid = false;
                            if(objects){
                                for(var i = 0; i< objects.length; i++){
                                    if(objects[i].class=="line")
                                    {
                                        showGrid = true; break;
                                    }
                                }
                            }
                            
                            afterCanvasLoad(canvas, g_groupOptions)
                            centerFocus()                            
                            props.setShowGrid(false)
                            props.setBoothLoaded(true)
                            mainCanvas.setBackgroundColor('transparent', mainCanvas.renderAll.bind(mainCanvas));                            
                            bgImgCanvas.setBackgroundColor('transparent', bgImgCanvas.renderAll.bind(bgImgCanvas))                        
                            bgCanvas.setBackgroundColor('transparent', bgCanvas.renderAll.bind(bgCanvas))
                                        
                            if(props.main.authInfo && props.main.authInfo.SALES){
                                var SELECTED_BOOTHS = props.main.authInfo.SALES.SELECTED_BOOTHS
                                var BOOTH_GROUPS_DISABLED = props.main.authInfo.SALES.BOOTH_GROUPS_DISABLED
                                var INVITE_CODE = props.main.authInfo.SALES.INVITE_CODE
                                var selectedBoothArray = [];
                                if(SELECTED_BOOTHS){
                                    selectedBoothArray = SELECTED_BOOTHS.split(",");
                                }
                                var groupDisabledArray = [];
                                if(BOOTH_GROUPS_DISABLED){
                                    groupDisabledArray = BOOTH_GROUPS_DISABLED.split(",");
                                    // groupDisabledArray = convertGroupIdArray(groupDisabledArray)
                                }
                                var bgColor = "#dadada"
                                if(selectedBoothArray.length > 0 || groupDisabledArray.length > 0){
                                    objects = canvas.getObjects();
                                    for(var i = 0; i< objects.length; i++){
                                        var group = objects[i]
                                        if(groupDisabledArray.includes(group.boothGroup)){
                                            // group.opacity = 0.5
                                            setObjectBG(canvas, group, bgColor)
                                            group.disabled = true
                                        }
                                        if(selectedBoothArray.includes(group.KEY_ID) && !group.sold){ 
                                            
                                            group.deselectedColor = getBGColor(group)         
                                            changeBorderColor(group, 'red')
                                            changeSingleBG(group, '#96FC3C')
                                            group.sale_selected = true
                                            addLocationIcon(mainCanvas,group,true)                                            
                                            // setObjectBG(canvas, group, bgColor)
                                        }
                                    }
                                }
                                if(props.main.authInfo.SALES.MAP_TYPE == 'sales' || props.cartPage){
                                    objects = canvas.getObjects();
                                    for(var i = 0; i< objects.length; i++){
                                        var group = objects[i]
                                        
                                        if(group.sold){
                                            // if(INVITE_CODE  && INVITE_CODE != ""){ //if invite
                                                // setObjectBG(canvas, group, LightenDarkenColor(getBGColor(group), 60)) //rest should be enabled
                                            // }
                                            if(INVITE_CODE  && INVITE_CODE != ""){ //if invite
                                                var polygons = group.getObjects('polygon')
                                                if(polygons && polygons.length){
                                                    var polygon = polygons[0];
                                                    var element = new fabric.Polygon(
                                                        polygon.points,
                                                        {
                                                        class:"mask",
                                                        left: polygon.left,
                                                        top: polygon.top,
                                                        fill: 'white', 
                                                        stroke: 'black',
                                                        strokeWidth: getStrokeWidth(mainCanvas),
                                                        lockRotation: true,
                                                        lockScalingX: true,
                                                        lockScalingY: true,
                                                        opacity: 0.4
                                                        // strokeUniform:true
                                                    });
                                                    group.add(element)
                                                    //   element.bringForward()
                                                }
                                            }
                                        }          
                                        else {
                                            if(group.holdBooth){
                                                group.disabled = true
                                            }
                                            if(!INVITE_CODE  || INVITE_CODE == ""){ // if no invite
                                                if(group.invite_code && group.invite_code != ""){ // booth with invite should be disabled
                                                    setObjectBG(canvas, group, bgColor)
                                                    group.disabled = true

                                                }
                                            }
                                            if(INVITE_CODE  && INVITE_CODE != ""){ //if invite
                                                if(INVITE_CODE == group.invite_code){ //matching booths should be disabled
                                                    //if invite code is matched
                                                    group.disabled = false
                                                }
                                                else{
                                                    setObjectBG(canvas, group, bgColor) //rest should be enabled
                                                    group.disabled = true
                                                }
                                            }
                                        }                              
                                    }
                                }

                                if(props.main.authInfo.SALES.AUTO_REFRESH == 'show' || props.main.authInfo.SALES.AUTO_REFRESH == true ){
                                    intervalLoadMapPeriodically = setInterval(() => {
                                        loadMapPeriodically(id)    
                                        setAjaxFailCnt(0)
                                        g_ajaxFailCnt = 0;
                                    }, 5000); 
                                }
                            }
                            props.setLoading(false)  
                            fabric.Object.prototype.objectCaching = false//true
                            canvas.renderOnAddRemove = true
                            props.onClearHistory();
                            props.setCanvasRefresh(true)
                            props.setBoothHistory({content: canvas.toDatalessObject()})
                            props.setFirstMapContent(canvas.toDatalessObject())
                            toggleDrawMode(canvas, "general")

                            for(var i = 0; i< groups.length; i++){
                                if(checkIfSelectable(groups[i])){
                                    groups[i].selectable = groups[i].evented = true;
                                    groups[i].lockMovementX = groups[i].lockMovementY = true;
                                    groups[i].lockScalingX = groups[i].lockScalingX = true;
                                    groups[i].lockRotation = true;
                                }                                
                            }
                            renderBorder(canvas); 
                            mainCanvas.renderAll();

                        });                        
                    }
                }
            }).catch(error => {
                console.log("error", error);
                props.setLoading(false)
            })
        }
        return ()=>{
            if(intervalLoadMapPeriodically){
                clearInterval(intervalLoadMapPeriodically);
                intervalLoadMapPeriodically = null
            }
        }
    }

    

    const loadOverview=async (canvasParam = null)=>{
        console.log("loadOverview")
        var curActiveFile = props.main.active_booth_file
        var activeFile;
        for(var i = 0; i <boothFileList.length; i++){
            if(boothFileList[i]['active']){
                activeFile = boothFileList[i];
                break;
            }
        }
        if(!activeFile && boothFileList.length){
            activeFile = boothFileList[0]
        }
        if(!activeFile || !activeFile?.KEY_ID){
            props.setLoading(false)
            return;
        }
        if(activeFile &&activeFile.KEY_ID  && activeFile.KEY_ID != curActiveFile.KEY_ID){ 
            var mapJS = activeFile.MAPJS;     
            var content = {}      
            if(mapJS != ""){
                mapJS = mapJS.replaceAll('\r\n', ' ')
                mapJS = JSON.parse(mapJS)
            }
            if(!mapJS || mapJS == "") mapJS = {}
            let tmp = {}
            mapJS = {...mapJS, ...tmp}
            props.setActiveBoothFile({...activeFile, ...mapJS})   
            
            // props.setLoading(true)
            
        }
    }

    const getMeta = (url, cb) => {
        const img = new Image();
        img.onload = () => cb(null, img);
        img.onerror = (err) => cb(err);
        img.src = url;
    };

    const loadMapPeriodically=async (id= null)=>{   
        if(intervalLoadMapPeriodically){
            clearInterval(intervalLoadMapPeriodically);
            intervalLoadMapPeriodically = null
        }     
        if(id){    
            var url = `${ESHOW_URL()}?method=getMapData&map_key=${id}&token=${getTokenCookie()}&WATERMARK=${watermark}`
            axiosInstance.get(url).then(res=>{
               
                try{
                    var objects = []
                    var bgObject
                    var deletedObjects = []
                    var booths= res.data.BOOTHS
                    var tables= res.data.TABLES
                    var annotations= res.data.ANNOTATIONS
                    var backgrounds= res.data.BACKGROUND
                    var deletes= res.data.DELETED
                    watermark = res.data.WATERMARK
                    
                    var convertRateX= Math.abs(mainCanvas.convertRateX)
                    if(booths && booths.length > 0){
                        for(var i = 0; i < booths.length; i++){
                            var INSERT_POINT = booths[i].INSERT_POINT
                            var left = 0;
                            var top = 0;
                            if(INSERT_POINT){
                                var tmp = INSERT_POINT.split(",")
                                if(tmp.length >=2){
                                    left = unit2Px(tmp[0], convertRateX, mainCanvas.mapUnit) + 50
                                    top = unit2Px(tmp[1], convertRateX, mainCanvas.mapUnit) + 50
                                }
                            }
                            var fabricJS = booths[i].FABRICJS
                            var exhibitors = booths[i].EXHIBITORS
                            var priceCheck = booths[i].PRICE?booths[i].PRICE:[]
                            var invite_code = booths[i].INVITE_CODE
                            if(fabricJS && fabricJS != ""){
                                var object = JSON.parse(fabricJS)
                                object.KEY_ID = booths[i].KEY_ID
                                object.invite_code = invite_code
                                object.exhibitors = exhibitors
                                object.priceCheck = priceCheck
                                object.sold = exhibitors && exhibitors.length>0?1:0
                                object.company = exhibitors?.[0].COMPANY_NAME
                                objects.push(object)
                            }
                        }
                    }
                    if(tables && tables.length > 0){
                        for(var i = 0; i < tables.length; i++){
                            var INSERT_POINT = tables[i].INSERT_POINT
                            var left = 0;
                            var top = 0;
                            if(INSERT_POINT){
                                var tmp = INSERT_POINT.split(",")
                                if(tmp.length >=2){
                                    left = unit2Px(tmp[0], convertRateX, mainCanvas.mapUnit) + 50
                                    top = unit2Px(tmp[1], convertRateX, mainCanvas.mapUnit) + 50
                                }
                            }
                            var fabricJS = tables[i].FABRICJS
                            if(fabricJS && fabricJS != ""){
                                var object = JSON.parse(fabricJS)
                                object.KEY_ID = tables[i].KEY_ID
                                // object.left = left
                                // object.top = top
                                objects.push(object)
                            }
                        }
                    }
                    if(annotations && annotations.length > 0){
                        for(var i = 0; i < annotations.length; i++){
                            var INSERT_POINT = annotations[i].INSERT_POINT
                            var left = 0;
                            var top = 0;
                            if(INSERT_POINT){
                                var tmp = INSERT_POINT.split(",")
                                if(tmp.length >=2){
                                    left = unit2Px(tmp[0], convertRateX, mainCanvas.mapUnit) + 50
                                    top = unit2Px(tmp[1], convertRateX, mainCanvas.mapUnit) + 50
                                }
                            }
                            var fabricJS = annotations[i].FABRICJS
                            if(fabricJS && fabricJS != ""){
                                var object = JSON.parse(fabricJS)
                                object.KEY_ID = annotations[i].KEY_ID
                                // object.left = left
                                // object.top = top
                                objects.push(object)
                            }
                        }
                    }
                    if(backgrounds && backgrounds.length > 0){
                        for(var i = 0; i < backgrounds.length; i++){
                            var url = backgrounds[i].URL
                            var offsetX = 0;
                            var offsetY = 0;
                            // var sizeX = backgrounds[i].SIZE_X*unitRate/convertRateX;
                            // var sizeY = backgrounds[i].SIZE_Y*unitRate/convertRateX;
                            var sizeX = backgrounds[i].SIZE_X;
                            var sizeY = backgrounds[i].SIZE_Y;
                            var KEY_ID = backgrounds[i].KEY_ID
                            var offset = backgrounds[i].INSERT_POINT;
                            if(offset && offset != ""){
                                var tmp = offset.split(",")
                                if(tmp && tmp.length > 1){
                                    offsetX = unit2Px(tmp[0], convertRateX, mainCanvas.mapUnit) + 50
                                    offsetY = unit2Px(tmp[1], convertRateX, mainCanvas.mapUnit) + 50
                                }
                            }
                            if(url && url != ""){
                                getMeta(assetUrl(url), (err, img) => {
                                    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
                                    
                                    var mapOffsetX = unit2Px(props.main.mainCanvas.mapOffsetX?props.main.mainCanvas.mapOffsetX:0, convertRateX, props.main.mainCanvas.mapUnit)
                                    var mapOffsetY = unit2Px(props.main.mainCanvas.mapOffsetY?props.main.mainCanvas.mapOffsetY:0, convertRateX, props.main.mainCanvas.mapUnit)
                                    bgImgCanvas.setBackgroundImage(assetUrl(url), ()=>{bgImgCanvas.renderAll(); props.setFirstBgContent(bgImgCanvas.toDatalessObject())}, {
                                        // opacity: 0,
                                        angle: 0,
                                        left: offsetX*1 + 50 + mapOffsetX,
                                        top: offsetY*1 + 50 + mapOffsetY,
                                        originX: 'left',
                                        originY: 'top',
                                        width: sizeX,
                                        height: sizeY,
                                        scaleX:sizeX/img.naturalWidth,
                                        scaleY: sizeY/img.naturalHeight,
                                        crossOrigin: 'anonymous',
                                        KEY_ID: KEY_ID
                                    })
                                });

                            }
                        }
                    }
                    if(deletes && deletes.length > 0){
                        for(var i = 0; i < deletes.length; i++){
                            var INSERT_POINT = deletes[i].INSERT_POINT
                            var left = 0;
                            var top = 0;
                            if(INSERT_POINT){
                                var tmp = INSERT_POINT.split(",")
                                if(tmp.length >=2){
                                    left = unit2Px(tmp[0], convertRateX, mainCanvas.mapUnit) + 50
                                    top = unit2Px(tmp[1], convertRateX, mainCanvas.mapUnit) + 50
                                }
                            }
                            var fabricJS = deletes[i].FABRICJS
                            if(fabricJS && fabricJS != ""){
                                var object = JSON.parse(fabricJS)
                                object.KEY_ID = deletes[i].KEY_ID
                                // object.left = left
                                // object.top = top
                                deletedObjects.push(object)
                            }
                        }
                    }

                    var existingObjects = mainCanvas.getObjects();
                    if(deletedObjects && deletedObjects.length > 0){
                        for(var i = 0; i< deletedObjects.length; i++){
                            var existingObject
                            if(existingObject = containsObject(deletedObjects[i], existingObjects, 'KEY_ID')){
                                mainCanvas.remove(existingObject)
                            }
                            else{

                            }
                        }
                    }
                    mainCanvas.renderAll()
                    existingObjects = mainCanvas.getObjects();

                    for(var i = 0; i< objects.length; i++){
                        var existingObject
                        if(existingObject = containsObject(objects[i], existingObjects, 'KEY_ID')){
                            objects[i].sale_selected = existingObject.sale_selected;
                            mainCanvas.remove(existingObject)
                        }
                        else{

                        }
                    }
                    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
                    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)

                    if(objects && objects.length > 0){
                        var defaultGroup = getDefaultGroup(g_groupOptions)
                        fabric.util.enlivenObjects(objects, function(objects) {
                            // var origRenderOnAddRemove = mainCanvas.renderOnAddRemove;
                            // mainCanvas.renderOnAddRemove = false;
                            
                            objects.forEach(function(object) {
                                object.set({
                                    borderColor: 'green',
                                    cornerColor: 'red',
                                    cornerSize: 6,
                                    hasControls: false,
                                    cornerSize: 6,
                                    transparentCorners: false,
                                    left: object.left+mapOffsetX,
                                    top: object.top+mapOffsetY
                                });
                                if(object.class == 'table'){
                                    var sub_objects = object.getObjects('group');
                                    if(sub_objects && sub_objects.length > 0){
                                        // if(sub_objects[0].class == "path-group"){
                                            sub_objects[0].set({
                                                left:NaN,
                                                top: NaN,
                                            })
                                            .setCoords();
                                        // }
                                    }
                                }
        
                                if(object.get('class')=="line")
                                {
        
                                }
                                if(object.get('class')=="booth"){
                                    
                                    var pathAs = object.getObjects('polygon')
                                    var width = 0;
                                    var height = 0;
                                    if(pathAs && pathAs[0]){
                                        pathAs[0].set('stroke', 'black')
                                    }
                                    var exhibitors = object.exhibitors
                                    var groupOptions = g_groupOptions
                                    var boothGroup = object.boothGroup;
                                    if(!boothGroup || boothGroup == '' || (boothGroup && !isUUID(boothGroup))){
                                        boothGroup = object.boothGroup = defaultGroup
                                    }
                                    if(object.holdBooth){
                                        var bgColor = "#dadada"
                                        for(var k = 0; k < groupOptions.length; k++){
                                            if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                                                var groupOption = groupOptions[k]
                                                    bgColor = convertRgbHex(groupOption['RESERVED']);
                                            }
                                        }
                                        setObjectBG(mainCanvas, object, bgColor)
                                    }
                                    else if(object.sold){
                                        var bgColor = "#dadada"
                                        for(var k = 0; k < groupOptions.length; k++){
                                            if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                                                var groupOption = groupOptions[k]
                                                    // bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                                                    var status = exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD';
                                                    bgColor = convertRgbHex(groupOption[status]);                        
                                                    // if(status == 'SOLD'){
                                                    //     bgColor = bgColor+"c0"
                                                    // }
                                            }
                                        }
                                        setObjectBG(mainCanvas, object, bgColor)
                                    }
                                    else{
                                        var bgColor = "#dadada"
                                        if(object.priceCheck && object.priceCheck.length > 0){
                                            for(var k = 0; k < groupOptions.length; k++){
                                                if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                                                    var groupOption = groupOptions[k]
                                                        bgColor = convertRgbHex(groupOption['AVAILABLE']);
                                                }
                                            }
                                        }
                                        setObjectBG(mainCanvas, object, bgColor)
                                    }
                                    var textObjects = object.getObjects('text');
                                    for(var k = 0; k< textObjects.length; k++){
                                        object.remove(textObjects[k])
                                    }
                                    addText(mainCanvas, object, (object.company?((object.company+"").trim()+"\n"):"")+""+object.booth_number)
                                    

                                }
                                
                                
                                object.set({
                                    borderColor: 'transparent',
                                    cornerColor: 'red',
                                    cornerSize: 6,
                                    hasControls: false,
                                    cornerSize: 6,
                                    transparentCorners: false
                                });
                                object.set({hasControls:true, selection:true, lockMovementX: true, lockMovementY:true, lockRotation:true, lockScalingX:true, lockScalingY:true})
                                if(object.class != "booth"){    
                                    object.hasBorders = false            
                                    object.selectable = object.evented = false;
                                }
                                mainCanvas.add(object);
                                changeBorderColor(object, 'black')
                                if(props.main.authInfo && props.main.authInfo.SALES){
                                    var SELECTED_BOOTHS = props.main.authInfo.SALES.SELECTED_BOOTHS
                                    var BOOTH_GROUPS_DISABLED = props.main.authInfo.SALES.BOOTH_GROUPS_DISABLED
                                    var INVITE_CODE = props.main.authInfo.SALES.INVITE_CODE
                                    var selectedBoothArray = [];
                                    if(SELECTED_BOOTHS){
                                        selectedBoothArray = SELECTED_BOOTHS.split(",");
                                    }
                                    var groupDisabledArray = [];
                                    if(BOOTH_GROUPS_DISABLED){
                                        groupDisabledArray = BOOTH_GROUPS_DISABLED.split(",");
                                        // groupDisabledArray = convertGroupIdArray(groupDisabledArray)
                                    }
                                    var bgColor = "#dadada"
                                    if(selectedBoothArray.length > 0 || groupDisabledArray.length > 0){
                                        if(groupDisabledArray.includes(object.boothGroup)){
                                            // object.opacity = 0.5
                                            setObjectBG(mainCanvas, object, bgColor)
                                            object.disabled = true
                                        }
                                        if(selectedBoothArray.includes(object.KEY_ID) || object.sale_selected){
                                            changeBorderColor(object, 'red')
                                            object.sale_selected = true
                                            addLocationIcon(mainCanvas,object,true)     
                                            // setObjectBG(canvas, group, bgColor)
                                        }
                                    }                                    
                                    if(props.main.authInfo.SALES.MAP_TYPE == 'sales' || props.cartPage){
                                        if(object.sold){
                                            if(INVITE_CODE  && INVITE_CODE != ""){ //if invite
                                                // setObjectBG(mainCanvas, object, LightenDarkenColor(getBGColor(object), 60)) //rest should be enabled
                                                var polygons = object.getObjects('polygon')
                                                if(polygons && polygons.length){
                                                    var polygon = polygons[0];
                                                    var element = new fabric.Polygon(
                                                        polygon.points,
                                                        {
                                                        class:"mask",
                                                        left: polygon.left,
                                                        top: polygon.top,
                                                        fill: 'white', 
                                                        stroke: 'black',
                                                        strokeWidth: getStrokeWidth(mainCanvas),
                                                        lockRotation: true,
                                                        lockScalingX: true,
                                                        lockScalingY: true,
                                                        opacity: 0.4
                                                        // strokeUniform:true
                                                    });
                                                    object.add(element)
                                                    //   element.bringForward()
                                                }
                                            }
                                        }
                                        else {
                                            if(object.holdBooth){
                                                object.disabled = true
                                            }
                                            if(!INVITE_CODE  || INVITE_CODE == ""){ // if no invite
                                                if(object.invite_code && object.invite_code != ""){ // booth with invite should be disabled
                                                    setObjectBG(mainCanvas, object, bgColor)
                                                    object.disabled = true

                                                }
                                            }
                                            if(INVITE_CODE  && INVITE_CODE != ""){ //if invite
                                                if(INVITE_CODE == object.invite_code){ //matching booths should be disabled
                                                    //if invite code is matched
                                                    object.disabled = false
                                                }
                                                else{
                                                    setObjectBG(mainCanvas, object, bgColor) //rest should be enabled
                                                    object.disabled = true
                                                }
                                            }
                                        }                                        
                                    }
                                }
                                mainCanvas.moveTo(object, object.zIndex)
                                object.moveTo(object.zIndex)
                            });
                            
                            // mainCanvas.renderOnAddRemove = origRenderOnAddRemove;
                            mainCanvas.renderAll();                            
                        });
                    }

                    if(bgObject){

                    }
                    
                    
                    if(props.main.authInfo && props.main.authInfo.SALES){
                        if(props.main.authInfo.SALES.AUTO_REFRESH == 'show' || props.main.authInfo.SALES.AUTO_REFRESH == true){
                            intervalLoadMapPeriodically = setInterval(() => {
                                loadMapPeriodically(id)    
                            }, 5000); 
                        }
                    }
                    if(g_ajaxFailCnt != 0){
                        setAjaxFailCnt(9999999);
                        setTimeout(function(){  
                            setAjaxFailCnt(0)
                        }, 100)
                    }
                    g_ajaxFailCnt = 0

                    

                } catch (e) {
                    if(props.main.authInfo && props.main.authInfo.SALES){
                        if(props.main.authInfo.SALES.AUTO_REFRESH == 'show' || props.main.authInfo.SALES.AUTO_REFRESH == true ){
                            intervalLoadMapPeriodically = setInterval(() => {
                                loadMapPeriodically(id)    
                            }, 5000); 
                        }
                    }
                    setAjaxFailCnt(ajaxFailCnt+1)
                    g_ajaxFailCnt = g_ajaxFailCnt+1
                }

            }).catch(error => {
                if(props.main.authInfo && props.main.authInfo.SALES){
                    if(props.main.authInfo.SALES.AUTO_REFRESH == 'show' || props.main.authInfo.SALES.AUTO_REFRESH == true){
                        intervalLoadMapPeriodically = setInterval(() => {
                            loadMapPeriodically(id)    
                        }, 5000); 
                    }
                }
                setAjaxFailCnt(ajaxFailCnt+1)
                g_ajaxFailCnt = g_ajaxFailCnt+1
                console.log("error", error);
                props.setLoading(false)
            })
        }
    }

    
    useEffect(()=>{
        if(mainCanvas){
            if(!boothFileList || (boothFileList && boothFileList.length==0)){
                fetchMapList()                
                // fetchGroupList()
                // fetchFeeGroupList()
                // fetchFeeCategoryList()
                // fetchPricingOptions()
                // fetchPremiumOptions()
                // fetchDiscountOptions()    
                // fetchMechnantAccount()   
                // fetchSponsorList()
            }         
        }
        
        return ()=>{
            if(intervalLoadMapPeriodically){
                clearInterval(intervalLoadMapPeriodically);
                intervalLoadMapPeriodically = null
            }
        }
    }, [mainCanvas])

    useEffect(()=>{
        if(props.main.boothFileList && props.main.mainCanvas){
            
            if(props.main.to2dFlag){ 
                props.to2D(false)
            }   
            else{
                var map_key;
                if(query.get("map")){   
                    map_key =  query.get("map")       
                }
                else{
                    map_key =  getLastFileKeyId(props.main.boothFileList)
                }
                // loadBoothById(map_key); 
                fetchCategoryOptions(map_key)    
            }
        }
    }, [props.main.boothFileList])

    
    useEffect(()=>{
        if(authInfo.authenticated == 3){
            var map_key;
            if(query.get("map")){   
                map_key =  query.get("map")       
            }
            else{
                map_key =  getLastFileKeyId(props.main.boothFileList)
            }
            // loadBoothById(map_key); 
            fetchCategoryOptions(map_key)   ;
            fetchSponsorList()
            fetchGroupList()
            fetchExhibitorList();
            fetchSponsorshipsList()
            if(0){
                fetchPricingOptions()
                fetchPremiumOptions()
                fetchDiscountOptions() 
            }
            
        }
    }, [query])

    
    
    useEffect(()=>{
            
        g_carts = props.cart.carts;
    }, [props.cart.carts])
    
    useEffect(()=>{
        if(mainCanvas){
            var layerSelect = props.main.layerSelect
            var objects = mainCanvas.getObjects();
            for(var i = 0; i < objects.length; i++){
                var object = objects[i];
                if(object.get('type') == 'group'){
                    var layer = object.get('layer'); 
                    if(layer){
                        object.set({visible: layerSelect[layer]})
                    }
                    var sub_objects = object.getObjects();
                    for(var j = 0; j < sub_objects.length; j++){
                        var sub_object = sub_objects[j];
                        var layer = sub_object.get('layer'); 
                        if(layer){
                            sub_object.set({visible: layerSelect[layer]})
                        }
                    }  
                }
                else{
                    var layer = object.get('layer'); 
                    if(layer){
                        object.set({visible: layerSelect[layer]})
                    }
                }
            }  
            mainCanvas.renderAll();
        }
    }, [props.main.layerSelect])

    const getConvertedSvg=(convertedSvgInfo) => {
        if(convertedSvgInfo){
            axiosInstance.get(convertedSvgInfo.contentStreamData, { headers: {  } }).then(res=>{
                const result = res.data;
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(result, "application/xml");
                // var svgDoc = doc.contentDocument;
                var svgStr = svgDoc.getElementsByTagName('svg')[0].outerHTML;
                if(mainCanvas){
                    props.setMainSvg(svgStr); 
                    if(svgStr){
                        var layers = parseLayer(svgStr)                        
                        props.setImportLayers(layers)
                    }
                }
            }).catch(error => {
                console.log("error", error);
            })
                
        }
    }


    
    
    useEffect(() => {  
        if(mainCanvas)  {
            setCanvasEvent()
        }
    }, [props.main.boothList])

    useEffect(() => {  
        if(mainCanvas && props.main.canvasRefresh)  {
            setCanvasEvent()
            props.setCanvasRefresh(false)
        }
    }, [props.main.canvasRefresh])

    useEffect(()=>{
        var mainCanvas = props.main.mainCanvas
    }, [props.main.gridSize,props.main.gridAngle, props.main.gridAngle , props.main.mainCanvas])

    // useEffect(() => {
    //     var mainCanvas = props.main.mainCanvas
    //     if(mainCanvas){
    //         mainCanvas.off('object:moving');
    //         mainCanvas.on('object:moving', onObjectMoving);
    //         props.setCanvasRefresh(true)
    //     }
    // }, [props.main.mainCanvas])
    const zoomProcess = ()=>{
        var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
        var zoom = mainCanvas.getZoom()
        var objects = mainCanvas.getObjects();
            // if(zoom/convertRateX>1)
        if(zoom < 50)
        {
            scaleTextsCenterFocus(mainCanvas, zoom)
        }
        
        for(var i = 0; i< objects.length; i++){
            if(objects[i].get('class')=="booth" || objects[i].get('class') == 'booth-group' || objects[i].get('class') == 'original-booth')
            {                                
                var group = objects[i];
                locationScaling(mainCanvas, group, false)
            }
        }        
        redrawRulers(this);
    }
    const dollyIn = ()=>{
        var zoom = mainCanvas.getZoom()
        zoom = zoom * 1.3;	
        
        mainCanvas.zoomToPoint({ x: mainCanvas.width/2, y: mainCanvas.height/2}, zoom);
        bgImgCanvas.zoomToPoint({ x: mainCanvas.width/2, y: mainCanvas.height/2}, zoom);
        bgCanvas.zoomToPoint({ x: mainCanvas.width/2, y: mainCanvas.height/2}, zoom);
        // mainCanvas.zoomToPoint({ x: mainCanvas.width/2+mainCanvas._offset.left, y: mainCanvas.height/2+mainCanvas._offset.top}, zoom);

        // mainCanvas.setZoom(zoom)
        mainCanvas.requestRenderAll();
        zoomProcess()
    }
    const dollyOut = ()=>{
        var zoom = mainCanvas.getZoom()
        zoom = zoom * 0.8;	
        mainCanvas.zoomToPoint({ x: mainCanvas.width/2, y: mainCanvas.height/2}, zoom);
        bgImgCanvas.zoomToPoint({ x: mainCanvas.width/2, y: mainCanvas.height/2}, zoom);
        bgCanvas.zoomToPoint({ x: mainCanvas.width/2, y: mainCanvas.height/2}, zoom);
        // mainCanvas.zoomToPoint({ x: mainCanvas.width/2+mainCanvas._offset.left, y: mainCanvas.height/2+mainCanvas._offset.top}, zoom);
        mainCanvas.requestRenderAll();
        zoomProcess()
    }
    const centerFocus=(canvas = null, render = true,  useZoomLevel = true)=>{

        var paddinTop = 55+64;
        var paddinTop1 = 55;
        var paddingBottom = 65;
        var paddingLeft = 65;        
        sales_header_show = true
        if(props.main.authInfo && props.main.authInfo.tokenInfo){
            if(props.main.authInfo.tokenInfo.HIDE_TOP_NAVIGATION){
            sales_header_show = false
            }
        }
        if(props.main.authInfo && props.main.authInfo.SALES  || props.cartPage){
            if(props.main.authInfo.SALES.RIGHT_MENU == 'hide' ||1){
                paddinTop-= 55 //remove header
                paddinTop1-= 55 //remove header
            }
            if(props.main.authInfo.SALES.SPONSORS == 'hide'){
                paddingBottom = 0
            }
            if(props.main.authInfo.SALES.MAP_TYPE == 'sales' || !sales_header_show || props.cartPage){
                sales_header_show = false
                paddinTop-= 64 //remove header
                paddinTop1 = 0;
            }   
            if(props.main.authInfo.SALES.LEFT_DRAWER == 'hide'){
                paddingLeft= 0 //remove header
            }      
            if(canvasBoundaryWidth<= 900) {
                paddingLeft= 0 //remove header
            }
            if(props.ui.openBoothList){
                if(canvasBoundaryWidth <= 900){//mobile
                    paddingBottom = canvasBoundaryHeight/2-32;
                }
                else
                    paddingLeft = 377;
            }
        }
        var mainCanvas = props.main.mainCanvas
        if(canvas){
            mainCanvas = canvas
        }
        var mapWidth = canvasBoundaryWidth;
        var mapHeight = canvasBoundaryHeight;
        if(mainCanvas.endX){
            var convertRateX= Math.abs(mainCanvas.convertRateX)
            var convertRateY= Math.abs(mainCanvas.convertRateY)
            mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
            mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
            if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){
                mapWidth = unit2Px(mainCanvas.mapWidth, convertRateX, mainCanvas.mapUnit)
                mapHeight = unit2Px(mainCanvas.mapHeight, convertRateX, mainCanvas.mapUnit)
            }
        }

        if(mapWidth && mapHeight){
            var zoomX = (canvasBoundaryWidth- 30 - paddingLeft)/(mapWidth) 
            var zoomY = (canvasBoundaryHeight - 30 - paddinTop - paddingBottom)/(mapHeight) 
            // var zoomX = (canvasBoundaryWidth- 50 - paddingLeft)/(mapWidth) *(props.main.authInfo?.SALES?.ZOOM_LEVEL?props.main.authInfo?.SALES?.ZOOM_LEVEL:1)
            // var zoomY = (canvasBoundaryHeight - 50 - paddinTop - paddingBottom)/(mapHeight) *(props.main.authInfo?.SALES?.ZOOM_LEVEL?props.main.authInfo?.SALES?.ZOOM_LEVEL:1)
            if(props.main.authInfo?.SALES?.ZOOM_LEVEL && useZoomLevel){
                zoomX = props.main.authInfo?.SALES?.ZOOM_LEVEL
                zoomY = props.main.authInfo?.SALES?.ZOOM_LEVEL
            }
            var zoom = Math.min(zoomX, zoomY)*1
            mainCanvas.viewportTransform[0] = Math.min(zoomX, zoomY)*1;
            mainCanvas.viewportTransform[3] = Math.min(zoomX, zoomY)*1;
            mainCanvas.viewportTransform[4] = 0;
            mainCanvas.viewportTransform[5] = 0;
            
            bgImgCanvas.viewportTransform[0] = Math.min(zoomX, zoomY)*1;
            bgImgCanvas.viewportTransform[3] = Math.min(zoomX, zoomY)*1;
            bgImgCanvas.viewportTransform[4] = 0;
            bgImgCanvas.viewportTransform[5] = 0;

            bgCanvas.viewportTransform[0] = Math.min(zoomX, zoomY)*1;
            bgCanvas.viewportTransform[3] = Math.min(zoomX, zoomY)*1;
            bgCanvas.viewportTransform[4] = 0;
            bgCanvas.viewportTransform[5] = 0;

            window.scrollTo(0, 0)
            if(render){
                bgImgCanvas.requestRenderAll();
                mainCanvas.requestRenderAll();
                bgCanvas.requestRenderAll();
            }
            if(Math.min(zoomX, zoomY) > 0){
                // var deltaX = ((canvasBoundaryWidth - paddingLeft) - mapWidth*Math.min(zoomX, zoomY)*1)/2;
                // var deltaY = ((canvasBoundaryHeight - paddinTop - paddingBottom) - mapHeight*Math.min(zoomX, zoomY)*1)/2;
                
                var deltaX = -50*zoom +((canvasBoundaryWidth - paddingLeft) - mapWidth*zoom)/2;
                var deltaY = -50*zoom + paddinTop1+ ((canvasBoundaryHeight - paddinTop - paddingBottom) - mapHeight*zoom)/2;

                var delta = new fabric.Point(deltaX, deltaY);
                mainCanvas.relativePan(delta);
                bgImgCanvas.relativePan(delta);
                bgCanvas.relativePan(delta);
                redrawRulers(mainCanvas)
            }

            var objects = mainCanvas.getObjects();
            var convertRateX= 1
            var convertRateY= 1
            if(mainCanvas.convertRateX) {
                var convertRateX= Math.abs(mainCanvas.convertRateX)
                var convertRateY= Math.abs(mainCanvas.convertRateY)
            }
            // if(zoom/convertRateX>1)
            {
                scaleTextsCenterFocus(mainCanvas, zoom)
            }

        }

        renderBorder(mainCanvas, render);

        
    }

    const onContextMenu=(event, mainCanvas)=> {
        if(!props.main.menuControl.open){
            if (mainCanvas.getActiveObject() && mainCanvas.getActiveObject().type == 'activeSelection') {
                mainCanvas.setActiveObject(mainCanvas.getActiveObject());
                //Display menu
                props.setMenuControl({open:true, left: event.clientX+5, top:event.clientY+5, target:mainCanvas.getActiveObject()})
            }
            else{
                var objects = mainCanvas.getObjects();
                for (var i = objects.length-1; i >= 0; i--) {
                    var object = objects[i];
                    //Determine whether the object is at the mouse click
                    if (object.containsPoint(new fabric.Point(event.offsetX, event.offsetY))) {
                        //Select the object
                        if(object.get('layer') == 'original') continue;
                        // if((object.get('class') == 'booth' || object.get('class') == 'booth-group'))
                        {
                            mainCanvas.setActiveObject(object);
                            //Display menu
                            props.setMenuControl({open:true, left: event.clientX+5, top:event.clientY+5, target:object})
                        }
                        break;
                    }
                }
            }
        }
    
    //Block the system right-click menu
        event.preventDefault();
        return false;

        // if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('class') == 'booth-group')){
        //     props.setMenuControl({open:true, left: opt.e.clientX+15, top:opt.e.clientY+15})
        // }
    }

    const processSelect=(opt)=>{
        if( opt.selected &&  opt.selected.length > 0){
            var selectedOne = opt.selected[0];
            if(selectedOne && (selectedOne.get('class') == 'booth' || selectedOne.get('class') == 'booth-group')){                            
                var id = selectedOne.id;
                var priced = false
                if(selectedOne.get('sold')){
                    priced = true
                }
                var childObjects = selectedOne.getObjects('rect');
                // for(var j = 0; j< childObjects.length; j++){
                //     if(childObjects[j].get('class') == 'color'){
                //         childObjects[j].set({fill: 'rgb(213, 5, 48)'})
                //         mainCanvas.renderAll();
                //         break;
                //     }
                // }
                if(g_carts){
                    for(var i = 0; i < g_carts.length; i++){
                        var booth = g_carts[i]
                        if(booth.id == id){
                            props.setLeftPage('cart')
                            props.setOpenBoothList(true)
                            props.setOpenUnassignedProfile(false)
                            props.setOpenCompanyProfile(false)
                            return;
                        }
                    }
                }
                if(priced){                   
                    // props.setOpenUnassignedProfile(false)
                    // props.setOpenCompanyProfile({booth:selectedOne, open:true})
                }
                else{
                    props.setOpenCompanyProfile(false)
                    // props.setOpenBoothList(false)
                    props.setOpenUnassignedProfile({booth:selectedOne, open:true})
                }
            }
        }
    }
    const processDeselect=(opt)=>{
        if( opt.deselected &&  opt.deselected.length > 0){
            var deselectedOne = opt.deselected[0];
            if(deselectedOne.get('sold') == '1'){
                // if(this.mouseOutTimeoutId){
                //     clearTimeout(this.mouseOutTimeoutId)
                // }
                // mouseModalRef.current.style.display = 'block'
                // mouseModalRef.current.style.left = opt.e.clientX+15+'px'
                // mouseModalRef.current.style.top = opt.e.clientY+15+'px'
                // mouseModalRef.current.innerHTML = "Company: "+company;
                // mainCanvas.renderAll();rgb(66 161 193)
                var childObjects = deselectedOne.getObjects('rect');
                // for(var j = 0; j< childObjects.length; j++){
                //     if(childObjects[j].get('class') == 'color'){
                //         childObjects[j].set({fill: '#4EB5D8'})
                //         childObjects[j].set({opacity: 1})
                //         childObjects[j].set({class: 'color'})
                //         mainCanvas.renderAll();
                //         break;
                //     }
                // }
            }
            else{
                // var childObjects = deselectedOne.getObjects('rect');
                // for(var j = 0; j< childObjects.length; j++){
                //     if(childObjects[j].get('class') == 'color'){
                //         childObjects[j].set({fill: '#53AE82'})
                //         childObjects[j].set({opacity: 1})
                //         childObjects[j].set({class: 'color'})
                //         mainCanvas.renderAll();
                //         break;
                //     }
                // }
            }
        }
    }
    const markActiveBooth = (target)=>{
        return;
        if(target.hightlighted) return;
        target.hasControls=false
        var convertRateX= 1
        if(mainCanvas.convertRateX) {
            convertRateX= Math.abs(mainCanvas.convertRateX)
        }
          if(target.class != 'booth' && target.layer != 'sponsorships') {
            target.selectable = false
            target.selectable = target.evented = false;
              return;
          }
          if(target.get('groupped')){
              var groups = target.getObjects('group');
              for(var groupInd = 0; groupInd < groups.length; groupInd++){
                    var group = groups[groupInd]
                    if(group.hovered){
                        group.deselectedColor = group.orgBg
                    }
                    else
                        group.deselectedColor = getBGColor(group)
                group.marked = 1;
                changeBorderColor(group, 'red')
                changeBorderWidth(group, getStrokeWidth(mainCanvas)*2)
                if(g_authInfo?.SALES?.MAP_TYPE == "sales"){
                changeSingleBG(group, '#96FC3C')           
                group.orgBg = ('#96FC3C');
                }
                else{
                changeSingleBG(group, '#96FC3C')           
                group.orgBg = ('#96FC3C');
                }
                mainCanvas.bringToFront(group);
                mainCanvas.renderAll()
              }
          }
          else{              
            if(target.class == "booth" || target.layer == 'sponsorships'){
                if(target.hovered){
                    target.deselectedColor = target.orgBg
                }
                else
                    target.deselectedColor = getBGColor(target)
                changeBorderColor(target, 'red')
                target.marked = 1;
                changeBorderWidth(target, getStrokeWidth(mainCanvas)*2)
                if(g_authInfo?.SALES?.MAP_TYPE == "sales"){
                    changeSingleBG(target, '#96FC3C')                    
                    target.orgBg = ('#96FC3C');
                }
                else{
                    if(target.greenBg){
                        changeSingleBG(target, '#96FC3C')                    
                        target.orgBg = ('#96FC3C');
                    }
                }
                // target.bringForward();
                mainCanvas.bringToFront(target);
                addLocationIcon(mainCanvas,target,true)
                mainCanvas.renderAll()
            }
          }
      }
    
      
      const markDeactivateAll = ()=>{        
        var active_objects = mainCanvas.getObjects();
        for(var ind = 0; ind < active_objects.length; ind++){
            var group = active_objects[ind];
            if(group.marked)
                markDeactiveBooth(group, false)
        }
        
        mainCanvas.renderAll()
      }
      const markDeactiveBooth = (target, renderFlag = true)=>{
          if(target.hightlighted) return;
          if(target.class != 'booth' && target.layer != 'sponsorships') return;
          var convertRateX= 1
          if(mainCanvas.convertRateX) {
              convertRateX= Math.abs(mainCanvas.convertRateX)
          }
          if(target.get('groupped')){
              var groups = target.getObjects('group');
              for(var groupInd = 0; groupInd < groups.length; groupInd++){
                  var group = groups[groupInd]
                  changeBorderColor(group, 'black')
                  group.marked = 0;
                  if(g_authInfo?.SALES?.MAP_TYPE == "sales"){
                    var bgColor = group.deselectedColor?group.deselectedColor:"#dadada"
                    changeSingleBG(group, bgColor)    
                    changeBorderWidth(target, getStrokeWidth(mainCanvas))                       
                    group.orgBg = bgColor;
                  }
                  else{
                    var bgColor = group.deselectedColor?group.deselectedColor:"#dadada"
                    changeSingleBG(group, bgColor)       
                    group.orgBg = bgColor;
                  }
                  group.greenBg = false
                //   group.sendBackwards();
                //   mainCanvas.sendToBack(group);
                if(renderFlag)
                  mainCanvas.renderAll()
              }
          }
          else{
                target.marked = 0;
                changeBorderColor(target, 'black')
                changeBorderWidth(target, getStrokeWidth(mainCanvas))    
                if(g_authInfo?.SALES?.MAP_TYPE == "sales"){
                    var bgColor = target.deselectedColor?target.deselectedColor:"#dadada"
                    changeSingleBG(target, bgColor)               
                    target.orgBg = bgColor;
                }
                else{
                    var bgColor = target.deselectedColor?target.deselectedColor:"#dadada"
                    changeSingleBG(target, bgColor)               
                    target.orgBg = bgColor;
                }
                target.greenBg = false
                removeLocationIcon(mainCanvas, target, false)
                // mainCanvas.sendToBack(target);
                // target.sendBackwards();
                if(renderFlag)
                    mainCanvas.renderAll()
          }
    
      }

      function searchTree(element, KEY_ID){
        if(element.KEY_ID == KEY_ID){
             return element;
        }else if (element.SUBCAT != null){
             var i;
             var result = null;
             for(i=0; result == null && i < element.SUBCAT.length; i++){
                  result = searchTree(element.SUBCAT[i], KEY_ID);
             }
             return result;
        }
        return null;
    }

    const getCategoryName = (KEY_ID)=>{
        var categoryData = {
            KEY_ID: "0",
            TITLE: "Parent",
            SUBCAT: []
          }
        if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
            // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
            categoryData.SUBCAT = (props.main.categoryOptions)
        }
        else{
        
        }
        var name  = ""
        var element = searchTree(categoryData, KEY_ID)
        if(element){
            name = element.TITLE
        }
        return name;
    }

    const salePurchaseBooth = (target, opt) =>{  
        // var zoom = mainCanvas.getZoom();
        // var ponter = opt.pointer;
        // var left = opt.e.clientX - (ponter.x - opt.target.left)*zoom
        // var top = opt.e.clientY - (ponter.y - opt.target.top)*zoom
        var left = opt.e.clientX
        var top = opt.e.clientY
        var center_x = left-35/2+'px'
        var center_y = top-35/2+'px'

        showProcessingCursor(true, center_x, center_y)
        
        var  map_key = active_booth_file.KEY_ID;
        var  booths = target.KEY_ID;
        var  parent_key;
        var  form_key;
        var remove_url = '';
        var purchase_url = '';
        if(props.main.authInfo && props.main.authInfo.SALES){
            form_key = props.main.authInfo.SALES.FORM_KEY
            parent_key = props.main.authInfo.SALES.PARENT_KEY
            remove_url = props.main.authInfo.SALES.REMOVE_URL
            purchase_url = props.main.authInfo.SALES.PURCHASE_URL
        }
        var query = `${ESHOW_URL()}?method=purchase_booth&form_key=${form_key}&booths=${booths}&booth_key=${booths}&map_key=${map_key}&parent_key=${parent_key}&${purchase_url}&token=${getTokenCookie()}`    
        if(target.layer == 'sponsorships'){
            query = `${query}&booth_no=${target.sponsorshipId}`
        }
        var url = query;
        var formdata = 
        {  
           query:query              
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            // props.setLoading(false)
            if(result.SUCCESS){  
                target.sale_selected = true
                markActiveBooth(target)
                mainCanvas.discardActiveObject();
                salesCallback(result, 'purchase_booth', query)
            }
            else{
            }
            if(result.MESSAGE){
                setOpenSnack(true);
                setSnackMsg((result.MESSAGE?(" "+result.MESSAGE):""));
            }
            showProcessingCursor(false, null, null)
        }).catch(error => {
            //console.log("error", error);
        })  
    }

    const salesRemoveBooth = (target,  opt) =>{
        // var zoom = mainCanvas.getZoom();
        // var ponter = opt.pointer;
        // var left = opt.e.clientX - (ponter.x - opt.target.left)*zoom
        // var top = opt.e.clientY - (ponter.y - opt.target.top)*zoom
        var left = opt.e.clientX
        var top = opt.e.clientY
        var center_x = left-35/2+'px'
        var center_y = top-35/2+'px'

        showProcessingCursor(true, center_x, center_y)
        var  map_key = active_booth_file.KEY_ID;
        var  booths = target.KEY_ID;
        var  parent_key;
        var  form_key;
        var remove_url = '';
        var purchase_url = '';
        if(props.main.authInfo && props.main.authInfo.SALES){
            form_key = props.main.authInfo.SALES.FORM_KEY
            parent_key = props.main.authInfo.SALES.PARENT_KEY
            remove_url = props.main.authInfo.SALES.REMOVE_URL
            purchase_url = props.main.authInfo.SALES.PURCHASE_URL
        }
        var query = `${ESHOW_URL()}?method=remove_booth&form_key=${form_key}&booths=${booths}&booth_key=${booths}&map_key=${map_key}&parent_key=${parent_key}&${remove_url}&token=${getTokenCookie()}`
        if(target.layer == 'sponsorships'){
            query = `${query}&booth_no=${target.sponsorshipId}`
        }
        var url = query
        var formdata = 
        {  
           query:query               
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            // props.setLoading(false)
            if(result.SUCCESS){  
                target.sale_selected = false
                markDeactiveBooth(target)
                mainCanvas.discardActiveObject();
                salesCallback(result, 'remove_booth', query)
            }
            else{
            }
            if(result.MESSAGE){
                setOpenSnack(true);
                setSnackMsg((result.MESSAGE?(" "+result.MESSAGE):""));
            }
        showProcessingCursor(false, null, null)
        }).catch(error => {
            //console.log("error", error);
        })  
    }

    
    const salesCallback = (result, method, query) =>{
        var  parent_key;
        var  form_key;
        var  callback;
        if(props.main.authInfo && props.main.authInfo.SALES){
            callback = props.main.authInfo.SALES.CALL_BACK
            form_key = props.main.authInfo.SALES.FORM_KEY
            parent_key = props.main.authInfo.SALES.PARENT_KEY
        }
        try{
            if(callback){
                const message = JSON.stringify({
                    callback: callback,
                    param: getTokenCookie(),
                    date: Date.now(),
                    response: result,
                    method: method,
                    // url : `https://dev.goeshow.com/webservices/eshow/floor_space.cfc?${query}`
                    url: `${ESHOW_URL()}?${query}`
                });
                window.parent.postMessage(message, '*');
            }
        }
        catch (e) {
            console.log("callback error", e)
        }
    }
    const salesCallback1 = () =>{
        var  parent_key;
        var  form_key;
        var  callback;
        if(props.main.authInfo && props.main.authInfo.SALES){
            callback = props.main.authInfo.SALES.CALL_BACK
            form_key = props.main.authInfo.SALES.FORM_KEY
            parent_key = props.main.authInfo.SALES.PARENT_KEY
        }
        var url = `${ESHOW_URL()}?method=${callback}&form_key=${form_key}&parent_key=${parent_key}&token=${getTokenCookie()}`
        var formdata = 
        {  
           query:`${ESHOW_URL()}?method=${callback}&form_key=${form_key}&parent_key=${parent_key}&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            // props.setLoading(false)
            if(result.status == "success"){ 
                
            }
            else{
            }
        }).catch(error => {
            //console.log("error", error);
        })  
    }

    const showProcessingCursor=(show, left = 0, top = 0)=>{
        if(show){
            mouseModalRef?.current?.classList?.remove("active");
            setMouseOverObject(false)
            mouseCursorRef.current.classList.add("active");
            mouseCursorRef.current.style.left = left
            mouseCursorRef.current.style.top = top
            // mouseModalRef.current.style.left = opt.e.clientX+15+'px'
            // mouseModalRef.current.style.top = opt.e.clientY+15+'px'
        }
        else{
            mouseCursorRef?.current?.classList?.remove("active");
        }
    }

    const setCanvasEvent = ()=>{
        mainCanvas.isDrawingMode = false;
        mainCanvas.selection = true;
        mainCanvas.forEachObject((o) => {
            o.selectable = o.evented = true;
            o.lockMovementX = o.lockMovementY = true;
            o.lockScalingX = o.lockScalingX = true;
            o.lockRotation = true;
        });

        mainCanvas.off('mouse:down');
        mainCanvas.on('mouse:down', function(opt) {               
            isMoving = false
            isZooming = false   
            this.isDragging = true;
            var e = opt.e;
            if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('class') == 'booth-group') && opt.target.get('sold') == '1'){
                // mouseModalRef.current.style.display = 'none'
            }
            // if (evt.ctrlKey === true) {
              this.isDragging = true;
              this.selection = false;
              if(e.type === 'mousedown'){
                this.lastPosX = e.clientX;
                this.lastPosY = e.clientY;
              }
              if(e.type === 'touchstart' && e.touches && e.touches.length){
                console.log("mouse:down touchstart")
                this.lastPosX = e.touches[0].clientX;
                this.lastPosY = e.touches[0].clientY;
              }

            // }
            
        });
        mainCanvas.off('mouse:up');
        mainCanvas.on('mouse:up', function(opt) {
            // on mouse up we want to recalculate new interaction
            // for all objects, so we call setViewportTransform
            this.setViewportTransform(this.viewportTransform);
            this.isDragging = false;
            
            if (isZooming || isMoving) {
                g_mouseUpFlag = false;
                console.log('Ignored mouse:up event due to zooming or moving.', isZooming, isMoving);    
                setTimeout(()=>{  
                    isMoving = false
                    isZooming = false   
                },200)              
                return;
            }

            this.selection = true;
            g_mouseUpFlag = true

            setTimeout(()=>{
                if(g_mouseUpFlag){
                    if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('class') == 'booth-group' || opt.target.get('layer') == 'sponsorships')){                            
                        var priced = false
                        if(opt.target.get('sold')){
                            priced = true
                            let exhibitors = opt.target.exhibitors;
                            if(exhibitors){
                                let LIST_PENDING_EXHIBITORS = active_booth_file.LIST_PENDING_EXHIBITORS
                                let LIST_TBD_EXHBITOR = active_booth_file.LIST_TBD_EXHBITOR 
                                if((!LIST_PENDING_EXHIBITORS && exhibitors[0]?.STATUS == 2) || (!LIST_TBD_EXHBITOR && opt.target.booth_number == 'TBD')){
                                    return;
                                }
                            }
                        }
                        
                        if(opt.target.get('layer') == 'sponsorships'){
                            props.setOpenUnassignedProfile(false)
                            props.setOpenCompanyProfile(false)
                            props.setOpenSponsorshipProfile({booth:opt.target, open:true})
                        }
                        else{
                            if(priced){
                                props.setOpenUnassignedProfile(false)
                                props.setOpenCompanyProfile({booth:opt.target, open:true})
                            }
                            else{
                                props.setOpenCompanyProfile(false)
                                props.setOpenUnassignedProfile({booth:opt.target, open:true})
                            }
                        }
                    }
                }
            }, 300)
        });
        

        mainCanvas.off('mouse:dblclick')
        mainCanvas.on('mouse:dblclick', function(opt) {
            g_mouseUpFlag = false;
            var id;
            if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('class') == 'booth-group')){                            
                var id = opt.target.id;
                StartSetBoothModal(id)
            }
            
            if(opt.target && (opt.target.get('class') == 'aisle')){                            
                var id = opt.target.id;
                StartSetAisleModal(opt.target)
            }
        });  
        
        mainCanvas.off('object:modified')            
        mainCanvas.on('object:modified', function(opt) {
            props.setBoothHistory({boothList:props.main.boothList, content: (props.main.mainCanvas.toDatalessObject())})
        });  

        // mainCanvas.off('touch:gesture')    
        // mainCanvas.on('touch:gesture', function(opt){
        //     if (opt.e.touches && opt.e.touches.length == 2) {
        //         alert(123);
        //         pausePanning = true;
        //         var point = new fabric.Point(opt.self.x, opt.self.y);
        //         if (opt.self.state == "start") {
        //             zoomStartScale = mainCanvas.getZoom();
        //         }
        //         var delta = zoomStartScale * opt.self.scale;
        //         mainCanvas.zoomToPoint(point, delta);
        //         pausePanning = false;
        //     }
        // });

        //   mainCanvas.on({
        //     'touch:gesture': function(e) {
        //         alert(123);
        //     },
        //     'touch:drag': function(e) {
        //         alert(123);
        //     },
        //     'touch:orientation': function(e) {
        //         alert(123);
        //     },
        //     'touch:shake': function(e) {
        //         alert(123);
        //     },
        //     'touch:longpress': function(e) {
        //         alert(123);
        //     }
        //   });

        mainCanvas.off('touch:gesture')    
        mainCanvas.on('touch:gesture', function (e) {
            return;
            if (e.self.touches && e.self.touches.length == 2) {
                console.log("touch:gesture ", e.self.touches.length, e.self.state, pinchFlag)
                // Get event point
                var point = new fabric.Point(e.self.x, e.self.y);
                // Remember canvas scale at gesture start
                if (e.self.state == "start") {
                    console.log("touch:gesture start")
                    zoomStartScale = mainCanvas.getZoom();
                    preScale = e.self.scale
                    pinchFlag = true
                }
                if (e.self.state === 'end' || e.self.state === 'cancel') {                    
                    console.log("touch:gesture end")
                    setTimeout(()=>{
                        isZooming = false;                  
                        console.log("touch:gesture timeout end")
                    }, 200)
                }
                if(!pinchFlag){
                    zoomStartScale = mainCanvas.getZoom();
                    preScale = e.self.scale
                    pinchFlag = true
                    console.log("touch:gesture start logic")
                }
                // Calculate delta from start scale
                if(pinchFlag == true){
                    
                    console.log("touch:gesture pinch", zoomStartScale,e.self.scale)
                    // if(Math.abs(e.self.scale - preScale) > 0.02){
                        var delta = zoomStartScale * e.self.scale;
                        // Zoom to pinch point

                        mainCanvas.zoomToPoint(point, delta);
                        bgImgCanvas.zoomToPoint(point, delta);
                        preScale = e.self.scale
                        zoomProcess()
                        
                        isZooming = true;
                        // Your zoom logic here
                        // setTimeout(() => isZooming = false, 100); // Reset flag after a short delay
                    // }
                }

            }
        });

        mainCanvas.off('mouse:wheel')    
        mainCanvas.on('mouse:wheel', function(opt) {
            console.log("mouse:wheel")
            var pointer = mainCanvas.getPointer(opt.e);//get translation (transform) position reference
            var posX = pointer.x;
            var posY = pointer.y;
            var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
            var mapWidth = Math.abs((mainCanvas.endX?mainCanvas.endX:0) - (mainCanvas.startX?mainCanvas.startX:0));
            var mapHeight = Math.abs((mainCanvas.endY?mainCanvas.endY:0) - (mainCanvas.startY?mainCanvas.startY:0));
            var insideFlag = false;
            if(posX >= 0 && posX <=mapWidth && posY >= 0 && posY <=mapHeight){
                insideFlag = true
            }
            if(!insideFlag){
                return
            }

            if(g_zoomTimeout){
                clearTimeout(g_zoomTimeout);
                g_zoomTimeout = false
            }
            var delta = opt.e.deltaY;
            var zoom = mainCanvas.getZoom();
            zoom *= 0.999 ** delta;
            var dir = opt.e.wheelDelta;
            if (zoom > 1000) {
                zoom = 1000;
            }
            else{
                if(dir < 0){
                    var mapWidth = canvasBoundaryWidth;
                    var mapHeight = canvasBoundaryHeight;
                    if(mainCanvas.endX){
                        var convertRateX= Math.abs(mainCanvas.convertRateX)
                        mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
                        mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
                        if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){                            
                            mapWidth = unit2Px(mainCanvas.mapWidth, convertRateX, mainCanvas.mapUnit)
                            mapHeight = unit2Px(mainCanvas.mapHeight, convertRateX, mainCanvas.mapUnit)
                        }
                    }
                    
                    var zoomX = (canvasBoundaryWidth- 50 - 0)/(mapWidth)
                    var zoomY = (canvasBoundaryHeight - 50 - 51)/(mapHeight)
                    var minZoom = Math.min(zoomX, zoomY)*0.5

                    if (zoom < minZoom) {
                        zoom = minZoom;
                        return;
                    }
                }
            }
            mainCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            bgImgCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            bgCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
            zoomProcess()
            isZooming = true;
            // Your zoom logic here
            // setTimeout(() => isZooming = false, 100); // Reset flag after a short delay
            // g_zoomTimeout = setTimeout(function(){
            //     console.log("set timeout")
            //     var objects = canvas.getObjects();
            //     for(var i = 0; i< objects.length; i++){
            //         if(objects[i].get('groupped') || objects[i].get('class')=="booth" || objects[i].get('class') == 'booth-group' || objects[i].get('class') == 'original-booth')
            //         {                            
            //             var object = objects[i];
            //             redrawobjectborder(canvas, zoom, object);
            //         }
            //         else if(objects[i].get('class') == 'line'){
            //             var o = objects[i];
            //             if (!o.strokeWidthUnscaled && o.strokeWidth) {
            //                 o.strokeWidthUnscaled = 1;
            //             }
            //             if (o.strokeWidthUnscaled) {
            //                 o.strokeWidth = o.strokeWidthUnscaled / zoom;
            //                 o.dirty= true
            //             }
            //         }
            //     }
            //     canvas.renderAll()
            //     for(var i = 0; i< objects.length; i++){
            //         if(objects[i].get('groupped') || objects[i].get('class')=="booth" || objects[i].get('class') == 'booth-group' || objects[i].get('class') == 'original-booth')
            //         {                            
            //             var object = objects[i];
            //             adjustobjectborder(canvas, zoom, object);                            
            //         }
            //     }
            //     canvas.setZoom(canvas.getZoom()*1.000000001)
            //     canvas.renderAll()
            // }, 1000)
        });

        mainCanvas.wrapperEl.addEventListener('touchstart', function(e) {
            if (e.touches.length > 1 ) {
                lastScale = mainCanvas.getZoom();
                isPinching = true;
                // initialDistance = getDistance(e.touches[0], e.touches[1]);
                // initialCenter = getCenter(e.touches[0], e.touches[1]);
                initialTouchData = Array.from(e.touches).map(touch => ({
                identifier: touch.identifier,
                x: touch.clientX,
                y: touch.clientY
                }));
                initialCenter = getCenter(initialTouchData);
                initialDistance = getAverageDistance(initialTouchData);
            }
          });
          
        mainCanvas.wrapperEl.addEventListener('touchmove', function(e) {
        if (isPinching && e.touches.length > 1) {
            const currentTouchData = Array.from(e.touches).map(touch => ({
                identifier: touch.identifier,
                x: touch.clientX,
                y: touch.clientY
            }));

            var currentDistance = getAverageDistance(currentTouchData);
            var scale = lastScale * (currentDistance / initialDistance);
            console.log("touchmove currentDistance", currentDistance, initialDistance)
        
            // if (scale < minScale) scale = minScale;
            // if (scale > maxScale) scale = maxScale;

            var currentCenter = getCenter(currentTouchData);
            var deltaX = currentCenter.x - initialCenter.x;
            var deltaY = currentCenter.y - initialCenter.y;
        
            var point = new fabric.Point(e.touches[0].clientX, e.touches[0].clientY);

            mainCanvas.zoomToPoint({ x: currentCenter.x, y: currentCenter.y }, scale);
            bgImgCanvas.zoomToPoint({ x: currentCenter.x, y: currentCenter.y }, scale);
            bgCanvas.zoomToPoint({ x: currentCenter.x, y: currentCenter.y }, scale);
            zoomProcess()
            mainCanvas.renderAll();
            isZooming = true;
        }
        });
          
          mainCanvas.wrapperEl.addEventListener('touchend', function(e) {
            if (isPinching && e.touches.length < 2) {
                isPinching = false;
                lastScale = mainCanvas.getZoom();
                initialTouchData = [];
                setTimeout(()=>{
                    isZooming = false;                  
                    console.log("touch:gesture timeout end")
                }, 200)
            }
          });
          
        //   function getDistance(touch1, touch2) {
        //     return Math.sqrt(Math.pow(touch2.clientX - touch1.clientX, 2) + Math.pow(touch2.clientY - touch1.clientY, 2));
        //   }

        //   function getCenter(touch1, touch2) {
        //     return {
        //       x: (touch1.clientX + touch2.clientX) / 2,
        //       y: (touch1.clientY + touch2.clientY) / 2
        //     };
        //   }

        function getCenter(touches) {
            const sum = touches.reduce((acc, touch) => {
                acc.x += touch.x;
                acc.y += touch.y;
                return acc;
            }, { x: 0, y: 0 });
            return {
                x: sum.x / touches.length,
                y: sum.y / touches.length
            };
        }
        
        function getDistance(touch1, touch2) {
            const deltaX = touch2.x - touch1.x;
            const deltaY = touch2.y - touch1.y;
            return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        }
        
        function getAverageDistance(touches) {
            let totalDistance = 0;
            for (let i = 0; i < touches.length; i++) {
                for (let j = i + 1; j < touches.length; j++) {
                    totalDistance += getDistance(touches[i], touches[j]);
                }
            }
            return totalDistance / (touches.length * (touches.length - 1) / 2);
        }

        mainCanvas.off('selection:updated')  
        mainCanvas.on('selection:updated', function(opt) {
            g_opt = opt            
            var target = mainCanvas.getActiveObject()
            target.hasControls=false
            target.hasBoder = false
            target.borderColor='transparent'
            setTimeout(()=>{
                console.log("selection:updated ", isZooming, isMoving)
                if (isZooming || isMoving) {
                    mainCanvas.discardActiveObject();
                    return true;
                }
                if(mainCanvas.getActiveObject()?.layer == 'venue')
                {
                    mainCanvas.getActiveObject().set({
                        selectable: false,
                        evented: false,
                        hasBoder: false,
                        hasControls:false
                    })
                    return;
                }
                var deselected = g_opt.deselected;
                if(deselected){
                    for(var ind = 0; ind < deselected.length; ind++){
                        var group = deselected[ind];
                        
                        if(props.main.authInfo?.SALES?.MAP_TYPE != "sales"){
                            markDeactiveBooth(group)
                        }
                        if(!checkifrectbooth(group)){
                            sendBackIntersects(group, mainCanvas)
                        }
                    }
                    
                    if(props.main.authInfo?.SALES?.MAP_TYPE != "sales"){
                        markDeactivateAll()
                    }
                }
                if(mainCanvas.getActiveObject()){
                    mainCanvas.getActiveObject().set({
                        lockScalingX: true,
                        lockScalingY: true,
                        borderColor: '#ff7777',
                        cornerColor: 'red',
                        cornerSize: 6,
                        hasControls: false,
                        cornerSize: 6,
                        transparentCorners: false,
                    })
                    if (mainCanvas.getActiveObject().type == 'activeSelection') {
                        var target = mainCanvas.getActiveObject()
                        target.hasControls=false
                        target.hasBoder = false
                        target.borderColor='transparent'
                        var active_objects = mainCanvas.getActiveObject().getObjects();
                        for(var ind = 0; ind < active_objects.length; ind++){
                            var group = active_objects[ind];
                            markActiveBooth(group)
                        }
                    }
                    else{
                        var target = mainCanvas.getActiveObject()
                        target.hasControls=false
                        target.hasBoder = false
                        target.borderColor='transparent'
                        var active_object = mainCanvas.getActiveObject();
                        var group = active_object;
                        if(props.main.authInfo?.SALES?.MAP_TYPE == "sales"){
                            if(props.main.authInfo?.SALES?.SELECT_BOOTH != "hide"){
                                var boothTarget = null
                                if(target.class == 'icon'){
                                    var boothTarget = getBoothByIcon(mainCanvas, target)
                                }
                                else if(target.class == 'booth' || target.layer == 'sponsorships'){
                                    boothTarget = target
                                }
                                if(boothTarget){
                                    var premiumCheck = boothTarget.get('premiumCheck')
                                    var priceCheck = boothTarget.get('priceCheck')
                                    if((premiumCheck&&premiumCheck.length > 0) || (priceCheck&&priceCheck.length > 0) && !boothTarget.disabled && !boothTarget.sold){
                                        if(boothTarget.sale_selected ){
                                            salesRemoveBooth(boothTarget, g_opt)
                                        }
                                        else{
                                            salePurchaseBooth(boothTarget, g_opt)
                                        }
                                    }
                                }
                            }
                            
                        }
                        else{
                            markActiveBooth(group)
                        }                 
                    
                    }
                }
                processDeselect(g_opt)
                processSelect(g_opt)
            },200)
            
        });  

        mainCanvas.off('selection:created')  
        mainCanvas.on('selection:created', function(opt) {
            g_opt = opt
            var target = mainCanvas.getActiveObject()
            target.hasControls=false
            target.hasBoder = false
            target.borderColor='transparent'
            setTimeout(()=>{
                console.log("selection:created ", isZooming, isMoving)
                if (isZooming || isMoving) {
                    return;
                }
                console.log("select", g_opt)
                if(!mainCanvas.getActiveObject())    return
                if(mainCanvas.getActiveObject().layer == 'venue')
                {
                    mainCanvas.getActiveObject().set({
                        selectable: false,
                        evented: false
                    })
                    return;
                }
                mainCanvas.getActiveObject().set({
                    lockScalingX: true,
                    lockScalingY: true,
                    borderColor: '#ff7777',
                    cornerColor: 'red',
                    cornerSize: 6,
                    hasControls: false,
                    cornerSize: 6,
                    transparentCorners: false,
                })
                
                if(props.main.authInfo?.SALES?.MAP_TYPE != "sales"){
                    markDeactivateAll()
                }
                if (mainCanvas.getActiveObject().type == 'activeSelection') {
                    var target = mainCanvas.getActiveObject()
                    target.hasControls=false
                    target.hasBoder = false
                    target.borderColor='transparent'
                    var active_objects = mainCanvas.getActiveObject().getObjects();
                    for(var ind = 0; ind < active_objects.length; ind++){
                        var group = active_objects[ind];
                        markActiveBooth(group)
                    }
                }
                else{
                    var target = mainCanvas.getActiveObject()
                    
                    

                    target.hasControls=false
                    target.hasBoder = false
                    target.borderColor='transparent'
                    var active_object = mainCanvas.getActiveObject();
                    var group = active_object;
                    if(props.main.authInfo?.SALES?.MAP_TYPE == "sales"){
                        if(props.main.authInfo?.SALES?.SELECT_BOOTH != "hide"){
                            var boothTarget = null
                            if(target.class == 'icon'){
                                var boothTarget = getBoothByIcon(mainCanvas, target)
                            }
                            else if(target.class == 'booth' || target.layer == 'sponsorships'){
                                boothTarget = target
                            }
                            if(boothTarget){
                                var premiumCheck = boothTarget.get('premiumCheck')
                                var priceCheck = boothTarget.get('priceCheck')
                                if((premiumCheck&&premiumCheck.length > 0) || (priceCheck&&priceCheck.length > 0) && !boothTarget.disabled && !boothTarget.sold){
                                    if(boothTarget.sale_selected ){
                                        salesRemoveBooth(boothTarget, g_opt)
                                    }
                                    else{
                                        salePurchaseBooth(boothTarget, g_opt)
                                    }
                                }
                            }
                        }
                    }
                    else{
                        markActiveBooth(group)
                    }                 
                }
                processSelect(g_opt)
            },200)
        }); 

        mainCanvas.off('selection:cleared')  
        mainCanvas.on('selection:cleared', function(opt) {
            
            if (isZooming || isMoving) {
                return;
            }
            var evt = opt.e;
            if ((evt && evt.ctrlKey === true) || props.ui.panFlag) {
                return false
            }
            var deselected = opt.deselected;
            if(deselected){
                for(var ind = 0; ind < deselected.length; ind++){
                    var group = deselected[ind];
                    if(props.main.authInfo?.SALES?.MAP_TYPE != "sales"){
                        markDeactiveBooth(group)
                    }
                    if(!checkifrectbooth(group)){
                        sendBackIntersects(group, mainCanvas)
                    }
                }
            }
            if(props.main.authInfo?.SALES?.MAP_TYPE != "sales"){
                markDeactivateAll()
            }
            processDeselect(opt)
        }); 
        
        mainCanvas.off('mouse:move')    
        mainCanvas.on('mouse:move', function(opt) {
            this.isMoving = true;
            var left = 0;
            var top = 0;
            if (this.isDragging) {
                var e = opt.e;
                var vpt = this.viewportTransform;
                if(e.type === 'mousemove'){
                    vpt[4] += e.clientX - this.lastPosX;
                    vpt[5] += e.clientY - this.lastPosY;
                    this.lastPosX = e.clientX;
                    this.lastPosY = e.clientY;
                    left = opt.e.clientX+15+'px'
                    top = opt.e.clientY+15+'px'
                }
                if(e.type === 'touchmove' && e.touches && e.touches.length){
                    console.log("touch move isDragging")
                    vpt[4] += e.touches[0].clientX - this.lastPosX;
                    vpt[5] += e.touches[0].clientY - this.lastPosY;
                    
                    if(Math.abs(e.touches[0].clientX - this.lastPosX) > 4)    {
                        isMoving = true;
                        console.log("mouse:move isMoving", isMoving)
                    }

                    this.lastPosX = e.touches[0].clientX;
                    this.lastPosY = e.touches[0].clientY;
                    left = e.touches[0].clientX+15+'px'
                    top = e.touches[0].clientY+15+'px'
                }
                this.requestRenderAll();
                bgImgCanvas.setViewportTransform(vpt);
                bgCanvas.setViewportTransform(vpt);
                return;
            }
            else{  
                var e = opt.e;
                if(e.type === 'mousemove'){
                    left = opt.e.clientX-17.5+'px'
                    top = opt.e.clientY-17.5+'px'
                }
                if(e.type === 'touchmove' && e.touches && e.touches.length){
                    console.log("touch move")
                    left = e.touches[0].clientX-17.5+'px'
                    top = e.touches[0].clientY-17.5+'px'
                }              
                // showProcessingCursor(true, left, top)
            }
        });

        mainCanvas.off('mouse:over')    
        mainCanvas.on('mouse:over', async function(opt) {
            if (opt.e?.type?.startsWith('touch')) return;
            if(!mouseModalRef?.current) return;
            var x =  opt.e.clientX;
            var y =  opt.e.clientY;
            if(x>=  mouseModalRef.current.offsetLeft && x <= mouseModalRef.current.offsetLeft+mouseModalRef.current.offsetWidth &&
                y>=  mouseModalRef.current.offsetTop && y <= mouseModalRef.current.offsetTop+mouseModalRef.current.offsetHeight
            ){

            }
            else{
                mouseModalRef.current.classList.remove("active");
                setMouseOverObject(false)
                mouseModalRef.current.style.left = -1000
                mouseModalRef.current.style.top = -1000
            }
            
            var activeObject = mainCanvas.getActiveObject();
            var activeObject = opt.target
            if(!activeObject) return;
            if(!checkIfSelectable(activeObject) || activeObject.layer == 'venue') return;
            mouseModalRef.current.style.minWidth='420px';
            mouseModalRef.current.style.width='auto';
            if(activeObject.sold){
                var exhibitorStatus = false;
                var exhibitors = activeObject?.exhibitors
                if(exhibitors && exhibitors.length > 0){
                    exhibitorStatus = exhibitors[0].STATUS
                }
                if(exhibitorStatus == 2 && props.main.exhibitorList?.SETUP && !props.main.exhibitorList?.SETUP?.LIST_PENDING_EXHIBITORS){
                    return;
                }
            }            
            var premiumCheck
            var priceCheck
            if(activeObject && activeObject.class == 'booth' || activeObject.get('layer') == 'sponsorships'){
                premiumCheck = activeObject.get('premiumCheck')
                priceCheck = activeObject.get('priceCheck')
                if((premiumCheck&&premiumCheck.length > 0) || (priceCheck&&priceCheck.length > 0) && !activeObject.disabled  && !activeObject.sold){
                    if(props.main.authInfo?.SALES?.SHOPPING_CART == 'hide'){
                        activeObject.hoverCursor='pointer'
                    }
                }
                if(activeObject.sold){
                    activeObject.hoverCursor='default'
                }
                if(props.main.authInfo.SALES.MAP_TYPE == 'sales' && activeObject.holdBooth || props.cartPage){
                    activeObject.hoverCursor='default'
                }

                var BOOTH_GROUPS_DISABLED = props.main.authInfo.SALES.BOOTH_GROUPS_DISABLED
                var INVITE_CODE = props.main.authInfo.SALES.INVITE_CODE
                var groupDisabledArray = [];
                if(BOOTH_GROUPS_DISABLED){
                    groupDisabledArray = BOOTH_GROUPS_DISABLED.split(",");
                }
                if(activeObject.boothGroup && activeObject.boothGroup != '' && groupDisabledArray.includes(activeObject.boothGroup)){
                    activeObject.hoverCursor='default'
                }
                if(activeObject.disabled){
                    activeObject.hoverCursor='default'
                }
            }


            if(activeObject && (activeObject.get('layer') == 'annotation' || activeObject.get('layer') == 'venue')){
                if(activeObject.description || activeObject.get('layer') == 'venue'){
                    mouseModalRef.current.classList.add("active");
                    if(opt.e.clientX + mouseModalRef.current.offsetWidth > canvasBoundaryWidth){
                        mouseModalRef.current.style.left = 'unset'
                        mouseModalRef.current.style.right = canvasBoundaryWidth - opt.e.clientX+15+'px'
                    }
                    else{
                        mouseModalRef.current.style.right = 'unset'
                        mouseModalRef.current.style.left = opt.e.clientX+15+'px'
                    }
                    if(opt.e.clientY + mouseModalRef.current.offsetHeight > canvasBoundaryHeight){
                        mouseModalRef.current.style.top = 'unset'
                        mouseModalRef.current.style.bottom = canvasBoundaryHeight - opt.e.clientY+15+'px'
                    }
                    else{
                        mouseModalRef.current.style.bottom = 'unset'
                        mouseModalRef.current.style.top = opt.e.clientY+15+'px'
                    }
                    setMouseOverObject(activeObject)                 
                    setMouseOverExibitorInfo(false)
                    setMouseOverBoothInfo(false)
                    setMouseOverExibitorKeyList([])
                }

            }
            if(activeObject && (activeObject.get('class') == 'booth' || activeObject.get('layer') == 'sponsorships')){
                var premiumCheck = [];
                var priceCheck = [];
                var discountCheck = [];
                var tmp1 = activeObject.get('premiumCheck')
                if(tmp1){
                    premiumCheck = (tmp1)
                }
                var tmp2 = activeObject.get('priceCheck')
                if(tmp2){
                    priceCheck = (tmp2)
                }
                var tmp3 = activeObject.get('discountCheck')
                if(tmp3){
                    discountCheck = (tmp3)
                }
                if(activeObject.disabled) {
                    return;
                }
                if(activeObject.get('sold') != '1') {
                    if(priceCheck.length > 0){
                        if(authInfo?.BOOTH_PRICING == "hide") return;
                        if(authInfo?.SALES?.BOOTH_PRICING == "hide") return;
                    }
                }
                activeObject.orgBg = getBGColor(activeObject);
                activeObject.hovered = true;
                setObjectBG(mainCanvas, activeObject, '#65AE2D')
                mainCanvas.renderAll()
                var pointer = mainCanvas.getPointer(opt.e);
                // var objectClientX = opt.e.clientX - (pointer.x - activeObject.left - activeObject.width/2 - activeObject.width*0.1 - unit2Px(1, mainCanvas.convertRateX))*mainCanvas.getZoom()
                // var objectClientY = opt.e.clientY - (pointer.y - activeObject.top - activeObject.height/2 - activeObject.height*0.1 - unit2Px(1, mainCanvas.convertRateX))*mainCanvas.getZoom()
                // mouseModalRef.current.style.left = objectClientX+'px'
                // mouseModalRef.current.style.top = objectClientY+'px'
                
                var convertRateX= 1
                if(mainCanvas.convertRateX) {
                    var convertRateX= Math.abs(mainCanvas.convertRateX)
                }
                var booth_number = (activeObject.booth_number)
                var booth_width = 0
                var booth_height = 0
                if(px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit) >= 5){                    
                    booth_width = px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                    booth_height = px2unit(activeObject.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                }
                else{
                    booth_width = px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                    booth_height = px2unit(activeObject.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                }

                if(axiosController){
                    axiosController.abort()
                    axiosController =false
                }
                axiosController = new AbortController();
                setMouseOverObject(activeObject)
                g_mouseEvent = opt
                var exhibitors = activeObject?.exhibitors
                var company_KEY_ID = false
                var holdBooth = activeObject.holdBooth
                var pending = false
                if(exhibitors && exhibitors.length > 0){
                    company_KEY_ID = exhibitors[0].EXHIBITOR_KEY
                    pending = exhibitors[0].STATUS == '2'
                }
                if(activeObject.get('layer') == 'sponsorships'){
                    if(activeObject.sponsorshipId){
                        setMouseOverExibitorInfo(false)
                        setMouseOverBoothInfo(false)
                        setMouseOverExibitorKeyList([])
                        mouseModalRef.current.classList.add("active");
                    }
                }
                else{
                    if(company_KEY_ID && !holdBooth && !pending && props.main.authInfo.SALES.MAP_TYPE != 'sales'){
                        mouseModalRef.current.style.width='420px';
                        getMouseOverExhibitor(company_KEY_ID, true, activeObject)
                        setMouseOverBoothInfo(false)
                    }
                    else{                    
                        mouseModalRef.current.style.minWidth='0px';
                        getMouseOverBooth(booth_number, company_KEY_ID)        
                        setMouseOverExibitorInfo(false)
                        setMouseOverExibitorKeyList([])
                    }
                }

                if(opt.e.clientX + mouseModalRef.current.offsetWidth/2 > canvasBoundaryWidth){
                    mouseModalRef.current.style.left = 'unset'
                    mouseModalRef.current.style.right = canvasBoundaryWidth-opt.e.clientX+'px'
                    // mouseModalRef.current.style.right = 5+'px'
                }
                else if(opt.e.clientX - mouseModalRef.current.offsetWidth/2 <0){
                    mouseModalRef.current.style.right = 'unset'
                    mouseModalRef.current.style.left = 5+'px'
                }
                else{
                    mouseModalRef.current.style.right = 'unset'
                    if(company_KEY_ID && !holdBooth && !pending && props.main.authInfo.SALES.MAP_TYPE != 'sales'){
                        mouseModalRef.current.style.left = opt.e.clientX- mouseModalRef.current.offsetWidth/2+'px'
                    }
                    else{
                        mouseModalRef.current.style.left = opt.e.clientX + 20 +'px'
                    }
                }
                if(opt.e.clientY > canvasBoundaryHeight){
                // if(opt.e.clientY + mouseModalRef.current.offsetHeight > canvasBoundaryHeight){
                    mouseModalRef.current.style.top = 'unset'
                    // mouseModalRef.current.style.bottom = canvasBoundaryHeight-opt.e.clientY+'px'
                    mouseModalRef.current.style.bottom = 5+'px'
                }
                else{
                    mouseModalRef.current.style.bottom = 'unset'
                    if(company_KEY_ID && !holdBooth && !pending && props.main.authInfo.SALES.MAP_TYPE != 'sales'){
                        mouseModalRef.current.style.top = opt.e.clientY - (pointer.y - activeObject.top - activeObject.height/2 - activeObject.height*0.1 - unit2Px(1, mainCanvas.convertRateX))*mainCanvas.getZoom()+'px'
                    }
                    else{
                        mouseModalRef.current.style.top = opt.e.clientY + 20 +'px'
                    }
                    // mouseModalRef.current.style.top = opt.e.clientY+'px'
                }
                mainCanvas.renderAll();
            }
        });

        mainCanvas.off('mouse:out')    
        mainCanvas.on('mouse:out', function(opt) {
            if(!mouseModalRef?.current) return;
            var x =  opt.e.clientX;
            var y =  opt.e.clientY;            
            if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('layer') == 'sponsorships')){
                if(opt.target.hovered){        
                    setObjectBG(mainCanvas, opt.target, opt.target.sale_selected?'#96FC3C':opt.target.orgBg)
                    opt.target.hovered = false
                    mainCanvas.renderAll();
                }
            }
            
            if(opt.target && (opt.target.get('class') == 'booth' || opt.target.layer == 'annotation' || opt.target.layer == 'sponsorships')){
                // g_mouseOutTimeout = setTimeout(function(){
                //     g_mouseOutTimeout = null
                    if(opt.target && (opt.target.get('class') == 'booth' || opt.target.layer == 'sponsorships')){
                        if((x+1)>=  mouseModalRef.current.offsetLeft && x <= mouseModalRef.current.offsetLeft+mouseModalRef.current.offsetWidth &&
                            (y+1)>=  mouseModalRef.current.offsetTop && y <= mouseModalRef.current.offsetTop+mouseModalRef.current.offsetHeight
                        )
                        return;
                    }
                        mouseModalRef.current.classList.remove("active");
                        setMouseOverObject(false)
                        
                        mouseModalRef.current.style.left = -1000
                        mouseModalRef.current.style.top = -1000
            }
        });
    }    
    const getExhibitor = async (KEY_ID)=>{
        var result = null
        var url = `${ESHOW_URL()}?method=getExhibitor&EXHIBITOR_KEY=${KEY_ID}&token=${getTokenCookie()}`;
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getExhibitor&EXHIBITOR_KEY=${KEY_ID}&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        const response = await axiosInstance.get(url);
        if(response.status == 200){
          result = response.data?.EXHIBITOR;
        }
        return result
    
    }

    const getMouseOverExhibitor = async (KEY_ID, mainFlag = true, activeObject)=>{
        setMouseOverExibitorInfo(false)
        if(mainFlag){
            setMouseOverExibitorKeyList([])
        }
        var result = null
        // KEY_ID = '078ABFD7-38EA-4CED-BC74-5E3C6CB6BEE8'
        var url = `${ESHOW_URL()}?method=getExhibitor&exhibitor_key=${KEY_ID}&token=${getTokenCookie()}&booth_key=${activeObject?.KEY_ID}`

        axiosInstance.get(url,{signal: axiosController.signal}).then(res=>{
            result = res.data?.EXHIBITOR;
            if(mainFlag){
                var tmp = [];
                tmp.push(result.KEY_ID);
                for(var i = 0; i < result.SUBEXHIBITORS?.length; i++){
                    tmp.push(result.SUBEXHIBITORS[i].EXHIBITOR_KEY)
                    // tmp.push(result.SUBEXHIBITORS[i].EXHIBITOR_KEY)
                    // tmp.push(result.SUBEXHIBITORS[i].EXHIBITOR_KEY)
                }
                setMouseOverExibitorKeyList(tmp)
            }
            setMouseOverExibitorInfo(result)
            if(g_mouseoverObject && mouseModalRef?.current){
                mouseModalRef.current.classList.add("active");
                setTimeout(()=>{
                    if(mouseModalRef.current.style.top.replace("px","")*1 + mouseModalRef.current.offsetHeight> canvasBoundaryHeight){
                        mouseModalRef.current.style.top = 'unset'
                        // mouseModalRef.current.style.bottom = 5+'px'
                        var pointer = mainCanvas.getPointer(g_mouseEvent.e);
                        mouseModalRef.current.style.bottom = (canvasBoundaryHeight-g_mouseEvent.e.clientY + (pointer.y - g_mouseoverObject.top - g_mouseoverObject.height*0.1)*mainCanvas.getZoom())+'px'
                    }
                }, 150)
            }

        }).catch(error => {
        })  
    
    }

    const getMouseOverBooth = async (booth_number, exhibitor_key = null)=>{
        setMouseOverBoothInfo(false)
        var result = null
        // KEY_ID = '078ABFD7-38EA-4CED-BC74-5E3C6CB6BEE8'
        var url = `${ESHOW_URL()}?method=getBoothProfile&map_key=${active_booth_file.KEY_ID}&booth_no=${booth_number}&token=${getTokenCookie()}&map_type=${props.main.authInfo.SALES.MAP_TYPE == 'sales'?'sales':'public'}`
       
        if(exhibitor_key){
            url = `${url}&exhibitor_key=${exhibitor_key}`
        }

        axiosInstance.get(url,{signal: axiosController.signal}).then(res=>{
            result = res.data;
            setMouseOverBoothInfo(result) 
            if(g_mouseoverObject){
                setTimeout(()=>{
                    var pointer = mainCanvas.getPointer(g_mouseEvent.e);
                    if(g_mouseEvent.e.clientX + mouseModalRef.current.offsetWidth> canvasBoundaryWidth){
                        mouseModalRef.current.style.left = 'unset'
                        // mouseModalRef.current.style.bottom = 5+'px'
                        // mouseModalRef.current.style.right = (canvasBoundaryWidth-g_mouseEvent.e.clientX + (pointer.x - g_mouseoverObject.left - g_mouseoverObject.width*0.1)*mainCanvas.getZoom())+'px'
                        mouseModalRef.current.style.right = canvasBoundaryWidth - g_mouseEvent.e.clientX + 20 +'px'
                    }
                    // else{
                    //     mouseModalRef.current.style.right = 'unset'
                    //     mouseModalRef.current.style.left = pointer.x+'px'
                    // }

                    if(g_mouseEvent.e.clientY + mouseModalRef.current.offsetHeight> canvasBoundaryHeight){
                        mouseModalRef.current.style.top = 'unset'
                        // mouseModalRef.current.style.bottom = 5+'px'
                        // mouseModalRef.current.style.bottom = (canvasBoundaryHeight-g_mouseEvent.e.clientY + (pointer.y - g_mouseoverObject.top - g_mouseoverObject.height*0.1)*mainCanvas.getZoom())+'px'
                        mouseModalRef.current.style.bottom = canvasBoundaryHeight - g_mouseEvent.e.clientY + 20 +'px'
                    }
                    // else{
                    //     mouseModalRef.current.style.bottom = 'unset'
                    //     mouseModalRef.current.style.top = pointer.y+'px'
                    // }
                    mouseModalRef.current.classList.add("active");
                }, 150)
            }

        }).catch(error => {
        })  
    
    }
    
    var newleft = 0
    var edgedetection = 30 //pixels to snap

    function findNewPos(distX, distY, target, obj) {
        // See whether to focus on X or Y axis
        if(Math.abs(distX) > Math.abs(distY)) {
            if (distX > 0) {
                target.set({left:(obj.left - target.width)})
            } else {
                target.set({left:(obj.left + obj.width)})
            }
        } else {
            if (distY > 0) {
                target.set({left:(obj.top - target.height)})
            } else {
                target.set({left:(obj.top + obj.height)})
            }
        }
    }
    useEffect(() => {
    }, [])

    


    const fetchMapList=async (save_id=false)=>{   
        var url = `${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`;
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            var tmp = [...res.data];
            var lastFile = null
            if(tmp.length > 0){
                tmp.sort(function (a, b) {
                    let fa = (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1,
                    fb = (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    // fa = (a.updatedAt?a.updatedAt:0)
                    // fb = (b.updatedAt?b.updatedAt:0)
                    // if (fa > fb) {
                    //   return -1;
                    // }
                    // if (fa < fb) {
                    //     return 1;
                    // }
                    return 0;
                  });
                lastFile = tmp[0]
            }
            props.setBoothFileList(tmp)

            var map_key;
            if(query.get("map")){   
                map_key =  query.get("map")       
            }
            else{
                map_key =  getLastFileKeyId(tmp)
            }
            console.log("load json from migrated")
            let mapJs = tmp?.['MAPJS'];
            let overview = false
            if(mapJs && mapJs != ''){
                try{
                    mapJs = JSON.parse(mapJs);
                }
                catch(e){
                
                }
                if(mapJs?.overview){
                    overview = true
                }
                else{
                }
            }
            loadBoothById(map_key, tmp); 
            
        }).catch(error => {
        })      
    }

    const getLastFileKeyId=(boothFileList)=>{
        var tmp = [...boothFileList];
        var lastFile = null
        if(tmp.length > 0){
            tmp.sort(function (a, b) {
                let fa = (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1,
                fb = (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            lastFile = tmp[0]
        }
        if(query.get('sales_map')){
            if(query.get('map')){
                lastFile = query.get('map')
            }
        }
        else{
            var lastFileSaved  = getCookie('last_map')    
            if(lastFileSaved){
                for(var i = 0; i <tmp.length; i++){
                    var mapJS = tmp[i].MAPJS;
                    try {
                        mapJS = mapJS.replaceAll('\r\n', ' ')
                        mapJS = JSON.parse(mapJS)
                        if((""+tmp[i]['KEY_ID']).toLowerCase() == (""+lastFileSaved).toLowerCase() && lastFileSaved){
                            lastFile = tmp[i]
                        }
                    }
                    catch(e){
                        continue;
                    }
                }
            }
        }
        return lastFile?lastFile['KEY_ID']:false
    }

    const initBoothFileList=()=>{     
        var boothFileList = props.main.boothFileList;   
        var tmp = [...boothFileList];
        for(var i = 0; i <tmp.length; i++){
            tmp[i] = {...tmp[i], active:false}
        }
        props.setBoothFileList(tmp)
    }

    var boothList = [];
    // lowerLeft:
    // x: 24
    // y: -1051.8
    // [[Prototype]]: Object
    // upperRight:
    // x: 1206
    // y: -112.2
    const getSvgInfo=(svgStr)=>{
        var drawermain = SVG(svgStr);
        var dimension_str = drawermain.find("#cadviewer_drawingCoordinates").text();
        var dimension = null;
        var convertRateX = 0;
        var convertRateY = 0;
        var convertOffsetX = 0;
        var convertOffsetY = 0;
        var startX = 0;
        var startY = 0;
        var endX = 0;
        var endY = 0;
        if(dimension_str && dimension_str.length>0){
            dimension = JSON.parse(dimension_str[0])
            var dwg_info = dimension.DWG;
            var svg_info = dimension.SVG;
            convertOffsetX = svg_info.lowerLeft.x - dwg_info.lowerLeft.x
            convertOffsetY = svg_info.upperRight.y - dwg_info.upperRight.y
            var width_svg = svg_info.upperRight.x - svg_info.lowerLeft.x
            var height_svg = svg_info.upperRight.y - svg_info.lowerLeft.y
            var width_dwg = dwg_info.upperRight.x - dwg_info.lowerLeft.x
            var height_dwg = dwg_info.upperRight.y - dwg_info.lowerLeft.y
            convertRateX = width_dwg/width_svg;
            convertRateY = height_dwg/height_svg;
            startX = svg_info.lowerLeft.x;
            startY = svg_info.lowerLeft.y;
            endX = svg_info.upperRight.x;
            endY = svg_info.upperRight.y;
        }
        mainCanvas.set({convertRateX, convertRateY, convertOffsetX, convertOffsetY, startX, startY, endX, endY})
        return {
            convertRateX, convertRateY, convertOffsetX, convertOffsetY
        };
    }
    const parseSvgPathChilds = (svgStr)=>{
        if(svgStr){
            var {convertRateX, convertRateY, convertOffsetX, convertOffsetY} = getSvgInfo(svgStr);
            var svgTopElement = jQuery("svg");
            var drawermain = SVG(svgStr);
            // drawermain.svg(svgStr);
            // console.log(drawermain.svg())
            mainCanvas.selectionColor = 'rgba(25, 118, 210,0.3)';
            mainCanvas.selectionBorderColor = 'rgba(255,0,0,0.8)';
            mainCanvas.selectionLineWidth = 3;

            for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
                var layer = mainLayers[layerInd];
                if(layer){
                    var topgroup = drawermain.find("#"+layer['id']);
                    if(topgroup && topgroup.length > 0){
                        if(layer['id'].toLowerCase().includes("boothoutline") ||1){
                            var secondgroups = topgroup[0].find("g");
                            if(secondgroups.length > 0){
                                var paths =secondgroups[0].find('path');
                                for(var i = 0; i < paths.length; i++){
                                    var path = paths[i];
                                    var pathbox = path.bbox();
                                    var drawer = SVG();
                                    var group = drawer.group();
                                    group.attr({
                                        fill:'none',
                                        stroke:"black",
                                        preserveObjectStacking: true
                                    })
                                    path.move(0,0)
                                    var svgPath = SVG(path);
                                    group.add(svgPath);
                                    // console.log(drawer.svg());
                                    // drawer.css({position:'absolute', left: pathbox.x, top: pathbox.y})
                                    // drawer.addTo('.render-svg').size(pathbox.width, pathbox.height)
                                    var renderposition = {};                
                                    renderposition.left = pathbox.x;
                                    renderposition.right = pathbox.y;

                                    var f_group = new fabric.Group().set({
                                        left : pathbox.x+mainOffsetX,
                                        top : pathbox.y+mainOffsetY,
                                        width : pathbox.width,
                                        height : pathbox.height,
                                        fill : 'green',
                                        id: 'booth-'+path.attr('id'),
                                        class: 'booth',
                                        layer:'booth',
                                        preserveObjectStacking: true,
                                        lockRotation: true,
                                        lockScalingX: true,
                                        lockScalingY: true,
                                    });	
                                    f_group.set({
                                        borderColor: 'green',
                                        cornerColor: 'red',
                                        cornerSize: 6,
                                        hasControls: false,
                                        cornerSize: 6,
                                        transparentCorners: false,
                                    });
                                    fabric.Object.prototype.transparentCorners = false;
                                    
                                    var fabricpath = fabric.loadSVGFromString(drawer.svg(),function(objects, options) {
                                        var obj = fabric.util.groupSVGElements(objects, options);
                                        // obj.scaleToHeight(mainCanvas.height-10)
                                            // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
                                        obj.set({
                                            left: 0-pathbox.width/2, 
                                            top: 0-pathbox.height/2, 
                                            visible:(layer.outline_selected || layer.number_selected),
                                            layer: (layer.outline_selected?'booth_outline':(layer.number_selected?'booth_number':''))
                                        })
                                        .setCoords();
                                        f_group.add(obj)
                                        mainCanvas.add(f_group).renderAll();
                                    });
                                    var newBooth = {
                                        id: path.attr('id')
                                    }
                                    boothList = [...boothList, newBooth]
                                }
                            }
                        }
                        else if(layer['id'].toLowerCase().includes("boothnumber")){

                        }
                        else{
                            var drawer = SVG();          
                            var secondgroups = topgroup[0].find("g");
                            if(secondgroups.length > 0){             
                                drawer.add(secondgroups[0]);   
                                var pathbox = topgroup[0].bbox();
                                secondgroups[0].move(0,0)
                                var f_group = new fabric.Group().set({
                                    left : pathbox.x+mainOffsetX,
                                    top : pathbox.y+mainOffsetY,
                                    width : pathbox.width,
                                    height : pathbox.height,
                                    fill : 'green',
                                    lockRotation: true,
                                    lockScalingX: true,
                                    lockScalingY: true,
                                });	
                                f_group.set({
                                    borderColor: 'green',
                                    cornerColor: 'red',
                                    cornerSize: 6,
                                    hasControls: false,
                                    cornerSize: 6,
                                    transparentCorners: false,
                                    layer:'booth',
                                    preserveObjectStacking: true
                                });
                                fabric.Object.prototype.transparentCorners = false;
                                fabric.loadSVGFromString(drawer.svg(),function(objects, options) {
                                    var obj = fabric.util.groupSVGElements(objects, options);
                                    // obj.scaleToHeight(mainCanvas.height-10)
                                        // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
                                    obj.set({
                                        left: 0-pathbox.width/2, 
                                        top: 0-pathbox.height/2, 
                                        visible:1,
                                        layer: (layer.outline_selected?'booth_outline':(layer.number_selected?'booth_number':''))
                                    })
                                    .setCoords();
                                    f_group.add(obj)
                                    mainCanvas.add(f_group).renderAll();
                                });
                            }
                        }
                    }
                }
            }
        }
    }
    const parseSvgTextChilds = (svgStr)=>{
        if(svgStr){
            var drawermain = SVG(svgStr);
            // drawermain.svg(svgStr);
            // console.log(drawermain.svg())
            // var topgroup = drawermain.find("[id*='layer_ESHOW_NUMBERS_FINAL']");
            for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
                var layer = mainLayers[layerInd];
                if(layer){
                    var topgroup = drawermain.find("#"+layer['id']);
                    if(topgroup && topgroup.length > 0){
                        if(layer['id'].toLowerCase().includes("boothnumber") || 1){
                            var secondgroups = topgroup[0].find("g");
                            if(secondgroups.length > 0){
                                for(var secondGroupInd = 0; secondGroupInd<secondgroups.length; secondGroupInd++){
                                    var paths = secondgroups[secondGroupInd].find('text');
                                    for(var i = 0; i < paths.length; i++){
                                        var path = paths[i];
                                        var fontSize = path.parent().attr('font-size');
                                        if(!fontSize) fontSize = 20
                                        var boothName = path.text();
                                        var parentGroup = path.parents(SVG.G);
                                        var pathbox = path.bbox();
                                        var pathtransform = path.transform();
                                        var drawer = SVG();
                                        var group = drawer.group();
                                        group.attr({
                                            fill:'black',
                                            stroke:"none",
                                            'font-size':'26.6497',
                                            "font-family": "arial, helvetica, sans-serif",
                                        })
                                            path.transform({
                                                translate:[0,0]
                                            })
                                        var svgPath = SVG(path);
                                        group.add(svgPath);
                                        var renderposition = {};                
                                        renderposition.left = pathtransform.translateX;
                                        renderposition.right = pathtransform.translateY;
                        
                                        if(boothName && !boothName.toString().includes("For Demonstration Purposes Only") && !boothName.toString().includes("Tailor Made Software")){
                                            if(layer.number_selected){
                                                var group_objects = mainCanvas.getObjects('group');
                                                for(var j = 0; j < group_objects.length; j++){
                                                    var group_object = group_objects[j]
                                                    if(group_object.get('class')=="booth" && group_object.containsPoint(new fabric.Point(pathtransform.translateX+mainOffsetX+pathbox.width/2, pathtransform.translateY+mainOffsetY+pathbox.height/2))){
                                                        
                                                        var f_text = new fabric.Text(boothName, {
                                                            left: pathtransform.translateX+mainOffsetX-group_object.left-group_object.width/2+10 -((Math.abs(Math.round(pathtransform.rotate*1)) ==90 || Math.abs(Math.round(pathtransform.rotate*1))==270)?(fontSize-5):0),
                                                            top: pathtransform.translateY+mainOffsetY-group_object.top-group_object.height/2 -((Math.abs(Math.round(pathtransform.rotate*1)) ==0 || Math.abs(Math.round(pathtransform.rotate*1))==180)?(fontSize-5):0),
                                                            fontSize:fontSize, 
                                                            visible:(layer.outline_selected || layer.number_selected),
                                                            angle: pathtransform.rotate,
                                                            layer: (layer.outline_selected?'booth_outline':(layer.number_selected?'booth_number':''))
                                                        });
                                                        group_object.add(f_text); 
                                                        for(var boothIndex = 0; boothIndex< boothList.length; boothIndex++){
                                                            var boothId = boothList[boothIndex].id;
                                                            if('booth-'+boothId == group_object.id){
                                                                boothList[boothIndex].boothName = boothName?boothName:"";
                                                            }
                                                        }
                                                        group_object.clone(function(cloned) {
                                                            cloned.set({
                                                                layer: 'original',
                                                                visible:false,
                                                                lockMovementX: true,
                                                                lockMovementY: true,
                                                                lockRotation: true,
                                                                lockScalingX: true,
                                                                lockScalingY: true,
                                                                selection:false
                                                            })
                                                            mainCanvas.add(cloned).renderAll();                                                            
                                                        });

                                                        break;
                                                    }
                                                }
                                            }
                                            else{
                                                if(pathtransform.rotate){
                                                }
                                                var f_text = new fabric.Text(boothName, {
                                                    left: pathtransform.translateX+mainOffsetX -((Math.abs(Math.round(pathtransform.rotate*1)) ==90 || Math.abs(Math.round(pathtransform.rotate*1))==270)?(fontSize-5):0),
                                                    top: pathtransform.translateY+mainOffsetY -((Math.abs(Math.round(pathtransform.rotate*1)) ==0 || Math.abs(Math.round(pathtransform.rotate*1))==180)?(fontSize-5):0),
                                                    fontSize:fontSize, 
                                                    visible:(layer.outline_selected || layer.number_selected),
                                                    angle: pathtransform.rotate
                                                });
                                                mainCanvas.add(f_text); 
                                            }
                                        }

                                        // var fabricpath = fabric.loadSVGFromString(drawer.svg(),function(objects, options) {
                                        //     var obj = fabric.util.groupSVGElements(objects, options);
                                        //     // obj.scaleToHeight(mainCanvas.height-10)
                                        //         // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
                                        //         obj.set({ left: pathtransform.translateX+mainOffsetX, top: pathtransform.translateY+mainOffsetY })
                                        //         .setCoords();
                                            
                                        //     mainCanvas.add(obj).renderAll();
                                        // });
                                    }                                      
                                }                  
                            }
                        }
                    }
                }
            }
            props.setBoothList(boothList);
            props.onClearHistory();
            mainCanvas.renderAll();
            props.setBoothHistory({boothList:boothList, content: (props.main.mainCanvas.toDatalessObject())})
        }
    }
    const parseLayer = (svgStr)=>{
        if(svgStr){
            var drawermain = SVG(svgStr);
            var layerGroups = drawermain.find("[id*='layer_']");
            var layers = [];
            if(layerGroups && layerGroups.length>0){
                for(var loop = 0; loop < layerGroups.length; loop++){
                    var layerGroup = layerGroups[loop];
                    if(layerGroup){
                        var layerid = layerGroup.attr('id');
                        var layername = layerGroup.attr('cvjs:layername')
                        layers.push({id:layerid, name:layername, active:true})
                    }
                }
            }
            return layers;
        }
    }

    const redrawRulers=(canvas)=> {
        return false
    }

    const handleBoothListItemClick = (value) => {
        setActiveBoothFileSelect(value)
        let overview = false
        let target
        for(let i = 0; i <boothFileList?.length; i++){
            if(boothFileList[i].KEY_ID == value)
            target = boothFileList[i]; break;
        }
        let mapJs = target?.['MAPJS'];
        if(mapJs && mapJs != ''){
            try{
                mapJs = JSON.parse(mapJs);
            }
            catch(e){
            
            }
            if(mapJs?.overview){
                overview = true
            }
            else{
            }
        }
        if(overview){
            loadOverview(); 
        }
        else{
            loadBoothById(value, boothFileList)
        }
    };

    
    useEffect(()=>{
        if(props.main.active_map_key){
            setActiveBoothFileSelect(props.main.active_map_key)
            loadBoothById(props.main.active_map_key, boothFileList)
            props.setActiveMapKey(false)
        }
    },[props.main.active_map_key])

    const getBoothList =() => {
        var boothList = [];
        if(props.main.mainCanvas){
          var objects = props.main.mainCanvas.getObjects();
          for(var i = 0; i< objects.length; i++){
            var obejct = objects[i]
            var row = {}
            if((obejct.get('class')=="booth" || obejct.get('class')=="booth-group") && obejct.get('sold') ){
              var textobjects = obejct.getObjects('text');
              textobjects.sort(function(a, b) {
                const nameA = a.get('left'); // ignore upper and lowercase
                const nameB = b.get('left'); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                // names must be equal
                return 0;
              })
              textobjects.sort(function(a, b) {
                const nameA = a.get('left'); // ignore upper and lowercase
                const nameB = b.get('left'); // ignore upper and lowercase
                return nameA - nameB;
              })
              if(textobjects && textobjects[0]){
                  var txt = "";
                  for(var j = 0; j < textobjects.length; j++){
                    if(textobjects[j].get('class')!="companyName")
                      txt+=(txt==""?"":" ")+textobjects[j].get('text')
                  }
                  row['name'] = txt;
              }
              row['priced'] = false
              var priceCheck = obejct.get('priceCheck');
              var premiumCheck = obejct.get('premiumCheck');
              if(premiumCheck){
                row['priced'] = true
              }
              if(priceCheck && priceCheck.length > 0){
                row['priced'] = true
              }
              if(obejct.get('sold')){
                row['priced'] = true
              }
              var groupId = obejct.get('id');
              row['id'] = groupId?groupId.replace("booth-",""):""
              row['company'] = obejct.get('company')
              row['boothgroup'] = obejct.get('boothGroup')
              row['boothType'] = obejct.get('boothType')
              row['bookmark'] = obejct.get('bookmark')
              row['company_tag_3dbooth'] = obejct.get('company_tag_3dbooth')
              row['company_category'] = obejct.get('company_category')
              row['company_tag_featured'] = obejct.get('company_tag_featured')
              row['booth'] = obejct
              if(row['company']){
                row['name'] = row['name'].replace(row['company'],"")
                row['name'] = row['name'].replace('\n',"")
              }
              boothList.push(row)
            }
          }
        }
    
        boothList.sort(function(a, b) {
          const nameA = a.company+" "+a.name; // ignore upper and lowercase
          const nameB = b.company+" "+a.name; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });
        boothList.sort(function(a, b) {
          return a.company - b.company;
        });
    
    
        return (boothList)
    }

    const doBookmark = (exhibitor)=>{
        if(exhibitor){
          try{
            const _token = getTokenPlanner();
            if((props.role !='admin') && (!_token || !_token)){
              props.setOpenLogin(true)
              return false;
            }
            exhibitor.BOOKMARKED = !exhibitor.BOOKMARKED
            setMouseOverExibitorInfo(exhibitor)
            callBookmarkApi(exhibitor, exhibitor.BOOKMARKED)
          }
          catch(e){
    
          }
        }
      }

    const handleOpenExportModal = ()=>{
        var svg = mainCanvas.toSVG({
            width:'100%',
            height: '100%',
            viewBox: {
                x:0,
                y:0,
                width:3000,
                height:3000
            }
        });
        setExportSvg(svg);
        setOpenExportModal(true)
    }
    const handleCloseExportModal = ()=>{
        setOpenExportModal(false)
    }

    const callBookmarkApi= async (exhibitor, value)=>{  
        const _token = getTokenPlanner();
        var BOOKMARKS = props.main.bookmarkList?props.main.bookmarkList:[]
        if(value){
          BOOKMARKS = [
            ...BOOKMARKS,
            {
              COMPANY_NAME: exhibitor?.DIRECTORY?.COMPANY_NAME?exhibitor?.DIRECTORY?.COMPANY_NAME:"",
              NOTES:"",
              STATUS:'',
              BOOTHS:exhibitor.BOOTHS,
              EXHIBITOR_KEY:exhibitor?.KEY_ID?exhibitor?.KEY_ID:"" 
            }
          ]
        }
        else{
          BOOKMARKS = BOOKMARKS.filter((item, index) => `${item.EXHIBITOR_KEY}` !== `${exhibitor.EXHIBITOR_KEY}`)
        }
        
        var postData =
        {
          SUCCESS:true,
          BOOKMARKS:BOOKMARKS
        };
    
        var formdata = {
          query:`${ESHOW_URL()}?method=putPlanner&token=${_token}`,
          postData: postData,
          returnResponse: true
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
          headers: {
            'body': '[{"company:","test"}]', 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${_token}`
          }
        }  
        var url = `${ESHOW_URL()}?method=putPlanner&token=${_token}`
        axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
          const eshowResponse = res.data;
          if(eshowResponse.SUCCESS == true){
          }
          else{
            props.setOpenSnack(true);
            props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to save bookmark");
          }
          props.setBookmarkList(BOOKMARKS)
        }).catch(error => {
            console.log("error", error);
        })
      }
      
    const renderMouseOverComponentContent = ()=>{        
        var sales_bookmarks_show = true
        if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
            sales_bookmarks_show = false
        }
        var activeObject = mouseoverObject
        if(!activeObject){
            return (
                <></>
            )
        }
        var booth_group_show = true
        if(props.main.authInfo && props.main.authInfo.SALES){
            if(props.main.authInfo.SALES.BOOTH_GROUP == 'hide'){
                booth_group_show = false
            }
        }

        var body = []
        if(activeObject.layer == "annotation" ){
            body.push(<div>{activeObject.description}</div>)
        }
        else if(activeObject.layer == "venue"){
            return (
                <></>
            )
        }
        else if(activeObject.layer == "sponsorships"){
            let sponsorshipsItem = false;
            let sponsorshipsOptions = props.main.sponsorshipsOptions
            sponsorshipsItem = sponsorshipsOptions?.find((item)=>item.SPONSORSHIP_ID == activeObject.sponsorshipId)
            if(!sponsorshipsItem){
                body.push(<div></div>)
                return;
            }
            if(sponsorshipsItem.FEATURED){
                body.push(<FeaturedLabel  moreClass='feet-content' style={{backgroundColor:'#EE5D10'}}/>)
            }
            if(sponsorshipsItem.HEADER){
                let headerVideo = false
                for(let i = 0; i < sponsorshipsItem.HEADER?.length; i++){
                    if(sponsorshipsItem.HEADER[i].MEDIA_TYPE == 'Video'){
                        headerVideo = sponsorshipsItem.HEADER[i].URL
                    }
                }
                if(headerVideo){
                    body.push(
                        <div style={{textAlign:'center', marginBottom:'15px'}}>
                            <ReactPlayer muted={true} playing={true} width="100%" height="220px" controls className="video" url={headerVideo} />                                                                     
                        </div>
                    )
                }
            }
            if(sponsorshipsItem.LOGO){
                body.push(
                    <div style={{textAlign:'center'}}>
                        <div className={`loader-${activeObject.sponsorshipId}`} style={{display:'block'}}><ScaleLoader color='#1976d2' radius={2} margin={2} height={30} width={4}/></div>   
                        <img 
                            onLoad={
                                (e)=>{
                                    var ele = document.getElementsByClassName(`loader-${activeObject.sponsorshipId}`)
                                    for (var i = 0; i < ele.length; i++ ) {
                                        ele[i].style.display = "none";
                                    }
                                }
                            } 
                            className='sponsorships-logo' width="100%" height="auto" src={assetUrl(sponsorshipsItem.LOGO)}/>                                                                     
                    </div>
                )
            }
            let imagesDiv = [];
            for(let i = 0; i < sponsorshipsItem.PRODUCTS?.length; i++){                
                imagesDiv.push(<img className='sponsorship-image' width={`32%`}  src={sponsorshipsItem.PRODUCTS[i].HEADER_IMAGE}/>)
            }
            if(sponsorshipsItem.PRODUCTS?.length){
                body.push(
                    <div className='sponsorship-images'>{imagesDiv}</div>
                )
            }
            setTimeout(()=>{
                if(mouseModalRef.current.style.top.replace("px","")*1 + mouseModalRef.current.offsetHeight> canvasBoundaryHeight){
                    mouseModalRef.current.style.top = 'unset'
                    // mouseModalRef.current.style.bottom = 5+'px'
                    var pointer = mainCanvas.getPointer(g_mouseEvent.e);
                    mouseModalRef.current.style.bottom = (canvasBoundaryHeight-g_mouseEvent.e.clientY + (pointer.y - g_mouseoverObject.top - g_mouseoverObject.height*0.1)*mainCanvas.getZoom())+'px'
                }
            }, 150)
        }
        else{            
            // if(!mouseOverExibitorInfo && activeObject.sold){
            //     body.push(<div></div>)
            //     return body;
            // }
            var convertRateX= 1
            var convertRateY= 1
            if(mainCanvas.convertRateX) {
                var convertRateX= Math.abs(mainCanvas.convertRateX)
                var convertRateY= Math.abs(mainCanvas.convertRateY)
            }
            var booth_number = (activeObject.booth_number)
            var booth_width = 0
            var booth_height = 0
            if(px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit) >= 5){      
                booth_width = px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                booth_height = px2unit(activeObject.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
            }
            else{
                booth_width = px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                booth_height = px2unit(activeObject.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
            }

            var booth_group = (activeObject.boothGroup?activeObject.boothGroup:"")
            var premiumCheck = [];
            var priceCheck = [];
            var discountCheck = [];
            var tmp1 = activeObject.get('premiumCheck')
            if(tmp1){
                premiumCheck = (tmp1)
            }
            var tmp2 = activeObject.get('priceCheck')
            if(tmp2){
                priceCheck = (tmp2)
            }
            var tmp3 = activeObject.get('discountCheck')
            if(tmp3){
                discountCheck = (tmp3)
            }
            if(mouseOverExibitorInfo){
                var company = "";//activeObject.company; 
                var company_description = activeObject.get('company_description');
                var company_category = activeObject.get('company_category');
                var company_log_url = activeObject.get('company_log_url');
                var company_tag_featured = false;
                var company_tag_member = false;
                var company_tag_3dbooth = false;
                var company_tag_sponser = false;
                var company_tag_level = false;
                var header_image =false
                var company_key =false
                var exhibitors = activeObject?.exhibitors
                var company_KEY_ID
                if(exhibitors && exhibitors.length > 0){
                    company_KEY_ID = exhibitors[0].EXHIBITOR_KEY
                }
                if(company_KEY_ID){
                    // var exhibitorInfo = await getExhibitor(company_KEY_ID)
                    // company_log_url = exhibitorInfo?.['DIRECTORY']['LOGO'];
                    // company_description = exhibitorInfo?.['DIRECTORY']['DESCRIPTION'];
                    // company_category = exhibitorInfo?.['DIRECTORY']['CATEGORY'];
                }
                if(mouseOverExibitorInfo){
                    company_log_url = mouseOverExibitorInfo?.['DIRECTORY']['LOGO'];
                    company_description = mouseOverExibitorInfo?.['DIRECTORY']['DESCRIPTION'];
                    company_category = mouseOverExibitorInfo?.['DIRECTORY']['CATEGORY'];
                    company = mouseOverExibitorInfo?.['DIRECTORY']['COMPANY_NAME'];
                    header_image = mouseOverExibitorInfo?.['DIRECTORY']['HEADERIMAGE'];
                    company_tag_featured = mouseOverExibitorInfo?.['DIRECTORY']['FEATURED'];
                    company_tag_member = mouseOverExibitorInfo?.['DIRECTORY']['MEMBER'];
                    company_tag_3dbooth = (mouseOverExibitorInfo?.['DIRECTORY']['3DMODEL']&&mouseOverExibitorInfo?.['DIRECTORY']['3DMODEL']!="")?true:false;
                    company_tag_sponser = mouseOverExibitorInfo?.['DIRECTORY']['SPONSOR'];
                    company_tag_level = mouseOverExibitorInfo?.['DIRECTORY']['BOOTHLEVEL'];
                    company_key = mouseOverExibitorInfo?.['DIRECTORY']['KEY_ID'];
                }
                if(company_tag_featured){
                    body.push(<FeaturedLabel moreClass='feet-content'/>)
                }
                if(header_image){
                    body.push(
                        <img style={{width:'calc(100% + 20px)', height:'90px', marginLeft:'-10px', marginRight:'-10px', marginTop:'-10px'}} width="auto" height="90px" src={assetUrl(header_image)}/>
                    )
                }                
                body.push(
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box>
                            <p>{company}</p>
                            <p>Booth #: {activeObject.booth_number}</p>
                        </Box>
                        {sales_bookmarks_show &&
                        <Box>
                            <IconButton style={{marginRight:0}} edge="end" onClick={()=>{ doBookmark(mouseOverExibitorInfo)}}>
                            {
                                mouseOverExibitorInfo.BOOKMARKED?
                                <BookmarkSharpIcon style={{color:'#e54839'}}/>:
                                <BookmarkBorderSharpIcon/>
                            }
                            </IconButton>
                        </Box>
                        }
                    </Box>
                )
                body.push(
                    <div mt={3} textAlign='center'>
                        {company_log_url &&
                        <div style={{textAlign:'center', marginTop:5}}>
                            <div className={`loader-${company_key}`} style={{display:'block'}}><ScaleLoader color='#1976d2' radius={2} margin={2} height={30} width={4}/></div>    
                            <img 
                                onLoad={
                                    (e)=>{
                                        var ele = document.getElementsByClassName(`loader-${company_key}`)
                                        for (var i = 0; i < ele.length; i++ ) {
                                            ele[i].style.display = "none";
                                        }
                                    }
                                } 
                                style={{maxWidth:'100%', height:'auto', maxHeight:'50px'}} width="auto" height="100px" src={assetUrl(company_log_url)}/> 
                        </div>
                        }
                        <div style={{paddingTop:"10px", paddingBottom:'10px'}}>
                            <ShowMore
                                lines={3}
                                more='Read More'
                                less='Read Less'
                                anchorClass='mouse-over-showmore'
                            >
                                {company_description?company_description:""} 
                            </ShowMore>
                        </div>
                    </div>
                )
                if(company_tag_member){
                    body.push(<div class="chip" style={{backgroundColor:'rgb(213 5 48)'}}><span>Member</span></div>)
                }
                if(company_tag_sponser){
                    body.push(<div class="chip" style={{backgroundColor:'rgb(213 5 48)'}}><span>Sponsor</span></div>)
                }
                if(company_tag_level){
                    body.push(<div class="chip" style={{backgroundColor:'rgb(213 5 48)'}}><span>{company_tag_level}</span></div>)
                }
                if(company_tag_3dbooth){
                    body.push(<div class="chip" style={{backgroundColor:'rgb(213 5 48)'}}><span>3D</span></div>)
                }
                if(company_category){
                    for(var i = 0; i < company_category.length; i++){
                        body.push(<div class="chip"><span>{company_category[i].ANSWER_TITLE}</span></div>)
                    }
                }
            }
            else if(mouseOverBoothInfo){
                for(var i = 0; i < mouseOverBoothInfo.BOOTH?.length; i++){
                    body.push(<p>{mouseOverBoothInfo.BOOTH[i].LABEL} {mouseOverBoothInfo.BOOTH[i].VALUE}</p>)
                }
                if(mouseOverBoothInfo.PRICE?.length)
                    body.push(<h4> Price</h4>)
                for(var i = 0; i < mouseOverBoothInfo.PRICE?.length; i++){
                    body.push(<p>{mouseOverBoothInfo.PRICE[i].LABEL} {mouseOverBoothInfo.PRICE[i].VALUE}</p>)
                }
            }     
        }
        return body;
    }

    const MouseOverCarouselItem=()=>
    {
        return (
            <div style={{height:'calc(100% - 35px)', overflowY:'auto', padding:'10px', boxSizing:'content-box'}}>
                {renderMouseOverComponentContent()}
            </div>
        )
    }

    const renderMouseOverComponent = ()=>{
        
        var activeObject = mouseoverObject
        return(
            <>
            {
                (!activeObject.sold || mouseOverExibitorKeyList?.length <= 1) || props.main.authInfo.SALES.MAP_TYPE == 'sales'?
                <div style={{padding:'10px', overflowY:'auto', maxHeight:'280px'}}>
                    {renderMouseOverComponentContent()}
                </div>:
                <div>
                    <Carousel
                        indicatorContainerProps={{
                            style: {
                                marginTop: '5px', // 5
                                marginBottom: '5px' // 4
                            }                    
                        }}
                        height={270}
                        autoPlay={false}
                        swipe={false}
                        navButtonsAlwaysVisible={true}
                        navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                            style: {
                                height:'33px',
                                bottom: '0px',
                                top: 'unset'
                            }
                        }} 
                        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                                padding:'3px',
                                background: 'rgb(67 65 65 / 41%)'
                                // margin:'0',
                            }
                        }} 
                        indicatorIconButtonProps={{
                            style:{
                                width: `${Math.min(15, 300/mouseOverExibitorKeyList.length)}px`,
                                height:`${Math.min(15, 300/mouseOverExibitorKeyList.length)}px`,
                            },
                            className:'custom-indicator'
                        }}
                        next={ (next, active) => {getMouseOverExhibitor(mouseOverExibitorKeyList[next], false, activeObject)} }
                        prev={ (prev, active) => {getMouseOverExhibitor(mouseOverExibitorKeyList[prev], false, activeObject)} }                
                    >
                        {
                            mouseOverExibitorKeyList?.map( (item, i) => <MouseOverCarouselItem key={i} exhibitor={item} /> )
                        }
                    </Carousel> 
                </div>
            }
            </>
        )
    }

    var sales_download_show = true
    var sales_right_menu_show = true
    var sales_top_menu_show = true
    var sales_3d_menu_show = true
    var sales_maxfit_show = true
    var sales_legend_show = true
    var sales_3dmap_show = true
    var sales_pdf_show = true
    var sales_auto_refresh = false
    var zoom_icon_show = true
    var fit_icon_show = true
    // var paddinTop = 51;
    var paddinTop = 64;
    var status_red
    var status_yellow
    var status_green
    var sales_sponsor_show = true
    if(props.main.authInfo && props.main.authInfo.SALES){
      if(props.main.authInfo.SALES.DOWNLOAD == 'hide'){
        sales_download_show = false
      }
      if(props.main.authInfo.SALES.RIGHT_MENU == 'hide'){
        sales_right_menu_show = false
        // paddinTop-= 55 //remove header
      }
      if(props.main.authInfo.SALES.HIDDE_TOP_NAVIGATION){        
        sales_top_menu_show = false
        paddinTop-= 55 //remove header
      }

      if(props.main.authInfo.SALES.SPONSORS == 'hide'){
        sales_sponsor_show = false
      }
      if(props.main.authInfo.SALES['3D_MAP'] == 'hide'){
        sales_3d_menu_show = false
      }
      if(props.main.authInfo.SALES['MAX_FIT'] == 'hide'){
        sales_maxfit_show = false
      }
      if(props.main.authInfo.SALES['LEGEND'] == 'hide'){
        sales_legend_show = false
      }
      if(props.main.authInfo.SALES['PDF'] == 'hide'){
        sales_pdf_show = false
      } 
      if(props.main.authInfo.SALES['FIT_ICON'] == 'hide'){
        fit_icon_show = false
      } 
      if(props.main.authInfo.SALES['ZOOM_ICON'] == 'hide'){
        zoom_icon_show = false
      }       
      var sales_header_show = true
      if(props.main.authInfo && props.main.authInfo.tokenInfo){
        if(props.main.authInfo.tokenInfo.HIDE_TOP_NAVIGATION){
          sales_header_show = false
        }
      }
      if(props.main.authInfo.SALES.MAP_TYPE == 'sales' || !sales_header_show || props.cartPage){
        sales_header_show = false;
        paddinTop-= 64 //remove header
      }   
      if(props.main.authInfo.SALES.AUTO_REFRESH == 'show' || props.main.authInfo.SALES.AUTO_REFRESH == true){
        sales_auto_refresh = true
        // if(ajaxFailCnt == 0){
            status_red = "/asset/green.png"
        // }else if(ajaxFailCnt == 1){
            status_yellow = "/asset/yellow.png"
        // }else if(ajaxFailCnt > 1){
            status_green = "/asset/red.png"
        // }
      }
    }
    var public_path = ''
    if(props.urlToken){
        public_path = props.main.authInfo.tokenInfo?.ESHOW_PATH
    }
    // var maincanvas_width = canvasBoundaryWidth
    // var maincanvas_height = canvasBoundaryHeight-paddinTop
    return (
        <>
        {authInfo.authenticated == 2&&
            <Notfound/>
        }
        {authInfo.authenticated == 3&& maincanvas_width && maincanvas_height &&
            <>
                <Box className="main-container xhbt-main-container"  sx={{ flexGrow: 1, height:(props.cartPage?'100%':'100vh')}}>
                    {/* <div className="toolbar">
                        <div className="furniture" style={{marginBottom:10}}>
                            <img id="10_10" draggable="true" src="/10x10.png"/>
                        </div>
                        <div className="furniture" style={{marginBottom:10}}>
                            <img id="10_20" draggable="true" src="/10x20.png"/>
                        </div>
                        <div className="furniture" style={{marginBottom:10}}>
                            <img id="tool-text" draggable="true" src="/text.png"/>
                        </div>
                    </div> */}
                    {/* <BoothList/> */}
                    {/* <CompanyProfile/> */}
                    {/* <ElementBox/> */}
                    {sales_top_menu_show &&
                        <div className='sub-header' style={{top:(sales_header_show?'70px':0)}}>
                            <Box style={{display:'flex', alignItems:'center'}}>
                                {!active_booth_file?.overview &&
                                <>
                                <Button className='bubble-btn' sx={{marginRight:'5px'}} title="Zoom In" onClick={()=>{dollyIn()}}><img  width="22" src="/asset/plus.png"/> <Box component="span" className={`${classes.btnLabel}  xhbt-MuiButtonBase-root`} sx={{ display: { xs: 'none', md: 'block' } }}>Zoom In</Box></Button>
                                <Button className='bubble-btn' sx={{marginRight:'5px'}} title="Zoom Out" onClick={()=>{dollyOut()}}><img  width="22" src="/asset/minus.png"/> <Box component="span" className={`${classes.btnLabel}  xhbt-MuiButtonBase-root`} sx={{ display: { xs: 'none', md: 'block' } }}>Zoom Out</Box></Button>
                                </>
                                }
                                {sales_maxfit_show&&
                                <Button className='bubble-btn' sx={{marginRight:'5px'}} title="Auto Fit on Screen" 
                                    onClick={()=>{                                        
                                        if(!active_booth_file?.overview)
                                            centerFocus(false, true, false)
                                        else
                                            swichFullOverView(props.main.overviewObject)
                                    }}>
                                        <img  width="22" src="/asset/fit.png"/><Box component="span" className={`${classes.btnLabel}  xhbt-MuiButtonBase-root`} sx={{ display: { xs: 'none', md: 'block' } }}>Max Fit</Box>
                                </Button>
                                }
                                {sales_legend_show&&
                                <Button className='bubble-btn' sx={{marginRight:'5px'}} title="Legend" onClick={()=>{setRightDragPopup('legend')}}><img  width="22" src="/asset/legend.png"/><Box component="span" className={`${classes.btnLabel}  xhbt-MuiButtonBase-root`} sx={{ display: { xs: 'none', md: 'block' } }}>Legend</Box></Button>                            
                                }
                                {sales_download_show && sales_pdf_show && !active_booth_file?.overview &&
                                <Button className='bubble-btn' sx={{marginRight:'5px'}} title="Download pdf" onClick={()=>{handleOpenExportModal()}}><img  width="22" src="/asset/pdf.png"/><Box component="span" className={`${classes.btnLabel}  xhbt-MuiButtonBase-root`} sx={{ display: { xs: 'none', md: 'block' } }}>Print</Box></Button>
                                }
                                {sales_3d_menu_show && sales_3dmap_show && !active_booth_file?.overview &&
                                <Button className='bubble-btn' sx={{marginRight:'5px'}} title="3D" 
                                
                                    onClick={()=>{
                                        props.setBoothHistory({content: mainCanvas.toDatalessObject()}); 
                                        if(props.urlToken){
                                            navigate(`${public_path}/three`); 
                                        }
                                        else
                                            navigate(`/three?map=${query.get('map')?query.get('map'):''}&show_key=${query.get('show_key')?query.get('show_key'):''}${query.get('sales_map')?"&sales_map=1":""}`); 
                                    }}
                                ><ThreeDRotationIcon/></Button>
                                }
                                {props.main.boothFileList?.length > 1 &&
                                    <FormControl className='xhbt-MuiFormControl-root' variant="standard" sx={{ ml: 1, minWidth: 120 }}>
                                        <Select
                                            className={`${classes.btnLabel}  xhbt-MuiInputBase-root bubble-btn`}
                                            value={active_booth_file_select?active_booth_file_select:""}
                                            // onChange={handleChange}
                                            onChange={(e) => handleBoothListItemClick(e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label'}}
                                            >
                                            <MenuItem value="">
                                                Select a map
                                            </MenuItem>
                                            {props.main.boothFileList.map((boothFile, index) => (                                        
                                                <MenuItem key={index} value={boothFile['KEY_ID']?boothFile['KEY_ID']:''}>{boothFile['TITLE']}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                            </Box>
                        </div>
                    }
                    <div className="canvas-viewer" style={{paddingTop: paddinTop}}>
                        <div>
                            <div style={{display:'flex',}}>
                                <div ref={mainCanvasDiv}>
                                    <canvas ref={mainCanvasRef} className="main-canvas" id="canvas" width={`${maincanvas_width}px`} height={`${maincanvas_height}px`}></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    {sales_sponsor_show && !props.cartPage &&
                    <Box sx={{
                        display: {
                          xs: "none",
                          md: "block"
                        }
                      }}
                      className={`${classes.footer} sub-footer xhbt-sub-footer`}
                    >
                        <Box className='xhbt-footer-logo-container' style={{display:'flex', minHeight:61}}> 
                            <Box className='xhbt-sponsor-logos' style={{width:'calc(100% - 150px)'}}>
                                <SponsorCarousel sponsorList = {sponsorList}/>
                            </Box>
                            <Box className='xhbt-goeshow-logo' style={{width: '150px', display:'flex', alignItems:'center'}}>
                                <a target="_blink" href={`${props.main.showSetup?.PUBLIC?.POWERED_BY?props.main.showSetup.PUBLIC.POWERED_BY:'javascript:void(0)'}`}>
                                    <img width="100%" src={`${ESHOW_URL()}?method=getPoweredBy`}/>                                       
                                </a>
                            </Box>
                        </Box>
                    </Box>
                    }
                </Box>
                {detectEnvironment() == 'desktop' &&
                <div onMouseLeave={()=>{console.log("mouse out"); mouseModalRef.current.classList.remove("active");
                        setMouseOverObject(false)
                    }} 
                    style={{width:-1000, height: -10000}} ref={mouseModalRef} 
                    className='mouseModal'
                >

                    {renderMouseOverComponent()}

                </div>
                }
                <div ref={mouseCursorRef} className='mouseCursorModal'>
                    <div class="loader"></div>
                </div>
                {selectedBooth&&
                <SetBoothModal
                    open={openSetBoothModal}
                    onClose={handleCloseSetBoothModal}
                    onOk={handleConfirmSetBoothModal}
                    booth = {selectedBooth}
                    onChange = {(key, value)=>onChangeSelectedBooth(key, value)}
                />
                }
                
                {selectedAisle&&
                <SetAisleModal
                    open={openSetAisleModal}
                    onClose={handleCloseSetAisleModal}
                    onOk={handleConfirmSetAisleModal}
                    aisle = {selectedAisle}
                />
                }
                
                {openExportModal&&
                <ExportFile
                    open={openExportModal}
                    onClose={handleCloseExportModal}
                    mainSvg={exportSvg}
                />
                }
                {/* <RightMenu/> */}
                {/* <AppLeftPanel/> */}
                <AppUnassignedPanel groupOptions = {boothGroups}/>
                <SetNewPasswordModal/>
                <ResetPasswordModal/>
                <LeftProfile groupOptions = {boothGroups}/>
                
                {rightDragPopup == 'legend' &&
                    <Draggable
                    handle=".handle"
                    defaultPosition={{x: 0, y: 0}}
                    position={null}
                    grid={[2, 2]}
                    scale={1}
                    bounds="body"
                    // onStart={this.handleStart}
                    // onDrag={this.handleDrag}
                    // onStop={this.handleStop}
                    >
                    <div className="legend-option client">
                        <Box display="flex" style={{justifyContent:'space-between', marginBottom: 10}}>
                        <DragIndicatorIcon fontSize="small" style={{cursor:'move', width:20}} className="handle"/>
                        <CloseIcon fontSize="small" style={{cursor:'pointer'}} onClick={()=>setRightDragPopup(false)}/>
                        </Box>
                        <Box className='legend-content' style={{textAlign:'center'}}>
                            <Box className="draw-buttons" display={'flex'} style={{marginBottom: 15, justifyContent:'center'}}>
                                <Table className="color-table"  aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width="34%">Title</TableCell>
                                            <TableCell width="16%">Sold</TableCell>
                                            <TableCell width="16%">Pending</TableCell>
                                            <TableCell width="16%">Available</TableCell>
                                            <TableCell width="16%">Reserved</TableCell>
                                            {/* <TableCell width="15%">Assign<br/>Group</TableCell> */}
                                        </TableRow>
                                    {props.main.groupOptions.map((row, index) => (
                                        <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell style={{wordBreak:'break-word'}}>{row.TITLE}</TableCell>
                                        <TableCell><Box  className="legend-color" style={{backgroundColor: convertRgb(row.SOLD)}}></Box></TableCell>
                                        <TableCell><Box  className="legend-color" style={{backgroundColor: convertRgb(row.PENDING)}}></Box></TableCell>
                                        <TableCell><Box  className="legend-color" style={{backgroundColor: convertRgb(row.AVAILABLE)}}></Box></TableCell>
                                        <TableCell><Box  className="legend-color" style={{backgroundColor: convertRgb(row.RESERVED)}}></Box></TableCell>
                                        {/* <TableCell><a href="javascript:void(0)" onClick={(e)=>props.handleSetBoothGroup(row.name, boothGroupName && boothGroupName==row.name?'unassign': 'assign')}>{boothGroupName && boothGroupName==row.name?'unassign': 'assign'}</a></TableCell> */}
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </div>
                    </Draggable>
                }
                {/* {
                    props.ui.openBoothDrawer &&
                    <BoothDrawer/>
                } */}
                {rightDragPopup == 'map' &&
                    <Draggable
                    handle=".handle"
                    defaultPosition={{x: 0, y: 0}}
                    position={null}
                    grid={[2, 2]}
                    scale={1}
                    bounds="body"
                    // onStart={this.handleStart}
                    // onDrag={this.handleDrag}
                    // onStop={this.handleStop}
                    >
                    <div className="boothlist-option client">
                        <Box display="flex" style={{justifyContent:'space-between', marginBottom: 10}}>
                        <DragIndicatorIcon fontSize="small" style={{cursor:'move', width:20}} className="handle"/>
                        <h4 style={{ margin: 0, marginTop: '3px' }}>Map Selection</h4>
                        <CloseIcon fontSize="small" style={{cursor:'pointer'}} onClick={()=>setRightDragPopup(false)}/>
                        </Box>
                        <Box style={{textAlign:'center', maxHeight: 600, overflowY:'auto'}}>
                            <Box className="draw-buttons" display={'flex'} style={{marginBottom: 15, justifyContent:'center'}}>
                                
                                <List sx={{ pt: 0, width: '100%' }}>
                                    {props.main.boothFileList.map((boothFile, index) => (
                                    <ListItem button onClick={() => handleBoothListItemClick(boothFile['KEY_ID'])} key={index}>
                                        <ListItemText primary={<span style={boothFile['KEY_ID']==query.get("map")?{color:'green', fontWeight:'bold'}:{}}>{boothFile['TITLE']}</span>} />
                                    </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    </div>
                    </Draggable>
                }
                {
                    <>
                        <div className='ajax-status'>
                            <img width="20px" style={{display:(g_ajaxFailCnt==0?"block":"none")}} src={status_red}/>
                        </div>
                        <div className='ajax-status'>
                            <img width="20px" style={{display:(g_ajaxFailCnt==1?"block":"none")}} src={status_yellow}/>
                        </div>
                        <div className='ajax-status'>
                            <img width="20px" style={{display:(g_ajaxFailCnt>1?"block":"none")}} src={status_green}/>
                        </div>
                    </>
                }
            </>
        }
        {/* {props.main.authInfo.SALES.MAP_TYPE == 'sales' && (zoom_icon_show || fit_icon_show) &&
        <div className="controls -ready public">
            {zoom_icon_show &&
            <>
            <Button style={{ marginRight: '5px' }} title="Zoom In" onClick={()=>{dollyIn()}}><ZoomInIcon/></Button>
            <Button style={{ marginRight: '5px' }}  title="Zoom Out" onClick={()=>{dollyOut()}}><ZoomOutIcon/></Button>
            </>
            }
            {fit_icon_show &&
            <Button title="Auto Fit on Screen" onClick={()=>{centerFocus(false, true, false)}}><FullscreenIcon/></Button>
            }
        </div>
        } */}
       <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            message={snackMsg}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />
        {props.ui?.customStyles?.['loaded'] &&
        <LoadingOverlay
            active={props.main.loadingFlag || authInfo.authenticated == 1}
            styles={{
                overlay: (base) => ({
                    ...base,
                    fontSize:'18px',
                    color: 'rgb(5, 37, 51)',
                    background: 'transparent',
                    position:'fixed',
                    zIndex:1000000000
                })
            }}
            spinner = {
            <ScaleLoader 
                color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'}
                radius={2.5} 
                margin={2.5} 
                height={40} 
                width={5}
            />}
            >
        </LoadingOverlay> 
        }
        </>
    );
  }
  
  
const mapStateToProps = (state) => {
    return {
      main: state.main,
      booth_history: state.booth_history.present,
      ui: state.ui,
      cart: state.cart,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {    
        setMainCanvas: canvas=>dispatch(setMainCanvas(canvas)),
        setMainLayers: layers=>dispatch(setMainLayers(layers)),
        setImportLayers: layers=>dispatch(setImportLayers(layers)),
        setMainSvg: svg=>dispatch(setMainSvg(svg)),
        setBoothList: data=>dispatch(setBoothList(data)),
        setBoothFileList: data=>dispatch(setBoothFileList(data)) ,
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),
        onClearHistory: () => dispatch(UndoActionCreators.clearHistory()),  
        setActiveBoothFile: data=>dispatch(setActiveBoothFile(data)),  
        setLoading:  data=>dispatch(setLoading(data)),
        setMenuControl:  data=>dispatch(setMenuControl(data)),
        setShowGrid:  data=>dispatch(setShowGrid(data)),
        setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
        setOpenBoothList:  data=>dispatch(setOpenBoothList(data)),
        setLeftPage:  data=>dispatch(setLeftPage(data)),
        setOpenUnassignedProfile:  data=>dispatch(setOpenUnassignedProfile(data)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),
        setCustomStyles: data=>dispatch(setCustomStyles(data)),
        setBoothLoaded: data=>dispatch(setBoothLoaded(data)),
        resetUI:  data=>dispatch(resetUI(data)),
        resetMain:  data=>dispatch(resetMain(data)),
        setPremiumOptions:  data=>dispatch(setPremiumOptions(data)),
        setPricingOptions:  data=>dispatch(setPricingOptions(data)),
        setDiscountOptions:  data=>dispatch(setDiscountOptions(data)),
        setGroupOptions:  data=>dispatch(setGroupOptions(data)),
        setExhibitorList:  data=>dispatch(setExhibitorList(data)),
        setCategoryOptions:  data=>dispatch(setCategoryOptions(data)),
        setAuthInfo:  data=>dispatch(setAuthInfo(data)),
        setFirstMapContent:  data=>dispatch(setFirstMapContent(data)),
        setBgImgCanvas: data=>dispatch(setBgImgCanvas(data)),
        setBgCanvas: data=>dispatch(setBgCanvas(data)),
        setFirstBgContent:  data=>dispatch(setFirstBgContent(data)),
        setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
        setSponsorshipsOptions:  data=>dispatch(setSponsorshipsOptions(data)),
        setOpenSponsorshipProfile: data=>dispatch(setOpenSponsorshipProfile(data)),
        setCartList: value=>dispatch(setCartList(value)),
        setActiveMapKey: value=>dispatch(setActiveMapKey(value)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(MainViewer);



  